.page-archives-index {
  text-align: left; background: #fff; padding-top: 5rem; font-size: 0.8em;
  a.video{cursor: pointer;}
  .archive-item { padding: 2rem 0; border-bottom: 1px solid #ddd;
    .title-container { color: #151515; text-align: left; }
    .title-container .uptitle { text-transform: uppercase; font-weight: bold; letter-spacing: 0.1em; font-size: 0.6rem; position: relative; display: inline-block; line-height: 3em; }
    .title-container .uptitle::before { content:""; position: absolute; bottom: 0; left: 0; width: 25%; height: 0.2rem; background-color: #fed700; }
    .title-container .title { text-transform: uppercase; font-size: 2.2em; margin: 0.6rem 0 1rem 0; font-weight: bold; line-height: 1; }
    .title-container .title small { font-size: 0.6em; }
    .summary { font-family: $nikon-text; padding-right: 7rem; padding-bottom: 2rem; }
    .head { display: flex; }
    .left-col { width: 83.75%; margin-right: 0.4%; }
    .right-col { width: 16.25%; }
    .right-col .video { margin-bottom: 2.2%; position: relative; cursor: pointer; }
    .right-col .video:hover::after,
    .right-col .video.active::after { content:""; position: absolute; left: 0; top: 0; bottom: 0; width: 0.2rem; background: #fed700; }
    .president { position: relative; margin-bottom: 3rem; }
    .president .name { text-align: right; line-height: 1.4rem; margin-top: 1rem; position: absolute; bottom: -1rem; padding: 0.2rem; right: 20%; background: #fff; font-weight: bold; text-transform: uppercase; font-size: 1.8em; }
    .president .title { line-height: 1rem; padding: 0.1rem 0.4rem; font-weight: bold; background: #fed700; left: -4rem;bottom: 1.5em; position: absolute; font-size: 0.5rem; text-transform: uppercase; min-height: 1rem; min-width: 4rem; }
    .body { display: flex; }
    a.video { position: relative; display: block; outline: none; }
    a.video img.play { position: absolute; top: 50%; left: 50%; width: 4rem; height: 4rem; margin-left: -2.0rem; margin-top: -2.0rem; }
    a.video .award { position: absolute; top: 0; left: 4%; width: 17%; padding: 2rem .6rem .6rem .6rem; background: #fed700; text-align: center; }
    a.video .award img { filter: grayscale(1) brightness(0); -webkit-filter: grayscale(1) brightness(0); max-width: 100%;}
    a.video .title { font-size: 1rem; text-shadow: 0px 0px 4px rgba(0,0,0,0.2); color: #fff; line-height: 1.1; position: absolute; top: 0; left: 22%; right: 0; padding: 1rem; }
    a.video .title strong { font-size: 1em; margin-bottom: 0.8rem; display: block; text-transform: uppercase;  }
  }
  a.see-more { display: inline-block; font-family: "Nexa"; line-height: 1.2em; padding: 0.6em 2em; border: 2px solid transparent; color: #000; background: white; position: relative; z-index: 1; font-weight: bold; text-transform: uppercase; margin: 0.1rem auto; }
  a.see-more:hover { background: #000; color: #fed700; }
  a.see-more::before { content: ""; position: absolute; border: 2px solid #000; top: -2px; right: -2px; bottom: -2px; left: -2px; z-index: -1; }
  a.see-more::after { content: ""; left: -1em; width: 2em; top: 0.7em; height: 0.8em; background: #fed700; display: block; position: absolute; z-index: -2; }
  a.see-more:hover::after { opacity: 0; }
  a.see-more.disabled { opacity: 0.5; }
  .mobile-only {
    @media screen and (max-width: 1000px) {
      display: block !important;
    }
  }
  @media screen and (max-width: 650px) {
     padding-top: 4rem;
     .summary { padding-right: 0; }
     .archive-item{
      .head { display: block; margin-bottom: 2rem; }
      .summary{padding-right: 0rem;}
      .left-col { width: 100%; }
      .right-col { width: 100%; }
      .president { width: 60%; margin: 0px 20%; }
      .president .title { bottom: 2.6rem; left:-2rem; }
      a.video img.play { left: 50%; width: 3rem; height: 3rem; margin-left: -2.5rem; margin-top: -2.5rem; }
      a.video .award { width: 25%; padding: 1rem .3rem .3rem .3rem;}
      a.video .title { position: relative; font-size: 0.8rem; text-shadow: none; color: #474747; background: #f0f0f0; top: auto; left: auto; right: 0; padding: 0.4rem; }
      a.video .title strong { font-size: 1rem; margin-bottom: 0; }
     }
     .carousel-indicators { text-align: center; position: relative; top: auto; right: auto; height: 1rem; margin: 1rem auto; }
     .carousel-indicators li { display: inline-block; margin: 0 0.2rem; float: none; background-color: #eae9e9; cursor:pointer; }
     .carousel-indicators li:hover,
     .carousel-indicators li.active { background-color: #fed700; }
  }

  .editions {
    text-transform: uppercase;
    font-family: $nikon-title;
    font-size: .6rem;
    font-weight: bold;

    .year {
      cursor: pointer;
      color: $nikon-gray-3;
      &.selected {
        color: $nikon-black;
        text-decoration: underline;
      }
    }
  }
}

.page-archive-single{
  text-align: left; background: #fff; padding-top: 7rem;font-size: .8em;
  a.video{cursor: pointer;}

  .title-container { color: #151515; text-align: left; }
  .title-container .uptitle { text-transform: uppercase; font-weight: bold; letter-spacing: 0.1em; font-size: 0.6rem; position: relative; display: inline-block; line-height: 3em; }
  .title-container .uptitle::before { content:""; position: absolute; bottom: 0; left: 0; width: 25%; height: 0.2rem; background-color: #fed700; }
  .title-container .title { text-transform: uppercase; font-size: 2.2em; margin: 0.6rem 0 1rem 0; font-weight: bold; line-height: 1; }
  .title-container .title small { font-size: 0.6em; }
  .summary { font-family: $nikon-text; padding-right: 7rem; padding-bottom: 2rem; }
  .head { display: flex; }
  .right-col .video { margin-bottom: 2.2%; position: relative; cursor: pointer; }
  .right-col .video:hover::after,
  .right-col .video.active::after { content:""; position: absolute; left: 0; top: 0; bottom: 0; width: 0.2rem; background: #fed700; }
  .president { position: relative; margin-bottom: 3rem; }
  .president .name { text-align: right; line-height: 1.4rem; margin-top: 1rem; position: absolute; bottom: -1rem; padding: 0.2rem; right: 20%; background: #fff; font-weight: bold; text-transform: uppercase; font-size: 1.8em; }
    .president .title { line-height: 1rem; padding: 0.1rem 0.4rem; font-weight: bold; background: #fed700; left: -4rem;bottom: 1.5em; position: absolute; font-size: 0.5rem; text-transform: uppercase; min-height: 1rem; min-width: 4rem; }
  .left-col { width: 83.75%; margin-right: 0.4%; }
  .right-col { width: 16.25%; }
  a.video { position: relative; display: block; outline: none; }
  a.video img.play { position: absolute; top: 50%; left: 50%; width: 7rem; height: 7rem; margin-left: -3.5rem; margin-top: -3.5rem; }
  a.video .award { position: absolute; top: 0; left: 4%; width: 17%; padding: 2rem 0.3rem 0.6rem 0.3rem; background: #fed700; text-align: center; }
  a.video .award img { filter: grayscale(1) brightness(0); -webkit-filter: grayscale(1) brightness(0); max-width: 100%;}
  a.video .title { font-size: 0.8rem; text-shadow: 0px 0px 4px rgba(0,0,0,0.2); color: #fff; line-height: 1.1; position: absolute; top: 0; left: 22%; right: 0; padding: 1.4em; }
  a.video .title strong { font-size: 1rem; margin-bottom: 0.8rem; display: block; text-transform: uppercase;  }
  a.video .title .description { display: none; }
  .body { display: block; }
  .main-award { margin-bottom: 1%; }
  .secondary-award.left { width: 49.5%; margin-bottom: 1%; float: left; }
  .secondary-award.right { width: 49.5%; margin-bottom: 1%; float: right; }
  .secondary-award a.video img.play { width: 5rem; height: 5rem; margin-left: -2.5rem; margin-top: -5.5rem; }
  .secondary-award a.video .title { font-family: $nikon-text; min-height: 6rem; position: relative; line-height: 1.2; font-size: 1.2em; text-shadow: none; color: #474747; background: #f0f0f0; top: auto; left: auto; right: 0; padding: 0.8rem; font-weight: bold; }
  .secondary-award a.video .title strong { font-family: "Nexa"; font-size: 1em; margin-bottom: 0; }
  .secondary-award a.video .title .description { font-family: $nikon-text; display: block; font-size: 0.9em; margin-top: 0.4rem; font-weight: normal; }
  .see-more-container { display: flex; padding-bottom: 2rem; }
  .see-more-container a.see-more { display: block; border: 2px solid #000; font-family: "Nexa"; line-height: 1.2em; padding: 1em 3em; color: #000; background: white; position: relative; z-index: 1; font-weight: bold; text-transform: uppercase; margin: 0.1rem auto; text-align: center; }
  .see-more-container a.see-more:hover { background: #000; color: #fed700; }
  @media screen and (max-width: 650px) {
    padding-top: 4rem;
    .head { display: block; margin-bottom: 2rem; }
    .left-col { width: 100%; }
    .right-col { width: 100%; }
    .president { width: 60%; margin: 0px 20%; }
    .president .title { bottom: 2.6rem; }
    .summary { padding-right: 0; }
    .secondary-award.left { width: 100%; }
    .secondary-award.right { width: 100%; }
    a.video img.play { width: 5rem; height: 5rem; margin-left: -2.5rem; margin-top: -5.5rem; }
    a.video .title { font-family: $nikon-text; min-height: auto; position: relative; line-height: 1.2; font-size: 0.9rem; text-shadow: none; color: #474747; background: #f0f0f0; top: auto; left: auto; right: 0; padding: 0.8rem; font-weight: bold; }
    a.video .title strong { font-family: "Nexa"; font-size: 1rem; margin-bottom: 0; }
    a.video .title .description { font-family: "Nexa"; display: block; font-size: 0.9rem; margin-top: 0.4rem; font-weight: normal; }
    .see-more-container { display: block; }
    .see-more-container a.see-more { padding: 0.6em; }
  }
}

