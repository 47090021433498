#annonces {
  font-size: 0.7rem; padding-top: 11em; padding-bottom:5em;
        @media screen and (max-width: 650px) {padding-top:3rem;}

  h1 { font-size: 1.5rem; padding-top:1rem; }

  p.subtitle {
    text-align: center;
    font-size: 1.2em;
    font-family: $nikon-text;
  }

  button {
    font-size: 1.1em;
    @media screen and (max-width: 650px) {margin-bottom:1em;}
  }

  .button.filter {
    text-transform: none;
    font-weight: normal;
    background: #eee;
    border: none;
    margin-left: 0.4rem;
    outline: none;
    padding: 0.2rem 0.5rem;
    &:hover {
      background: #aaa;
      color: #fff;
    }
  }

  .custom-select.filter {
    width: auto; height: auto;
    background: #eee; border: none; border-radius: 0;
    padding: 0.25rem 0.5rem;
    font-size: 0.55rem;
    background-image:
      linear-gradient(48deg, transparent 50%, #495057 56%),
      linear-gradient(132deg, #495057 50%, transparent 56%) !important;
    background-position:
      calc(100% - 1.4em) 1em,
      calc(100% - 0.9em) 1em,
      100% 0;
    background-size: 0.5em 0.5em, 0.5em 0.5em;
    background-repeat: no-repeat;
    -webkit-appearance: none; -moz-appearance: none; outline: none;
  }

  .button.filter.selected{ background: #333; color: #fff;}

  .noresult{text-align:center;padding-top:2rem;}

  .annonces{ width: 100%; margin-top: 1em;}

  .annonce-wrapper {
    float: left; border: none; border-radius: 0;
    width: calc(33% - 1.25rem);
    margin-left: 1rem;
    margin-bottom: 1em;
          @media screen and (max-width: 650px) {width:90%;}
    .annonce {
      position: relative; padding: .9rem;
       background: #eee;

      .type {
        position: absolute;right: 0;top: 0;
        font-weight: bold; text-transform: uppercase;
        padding: 0.1rem 0.3rem;
        &.cherche, &.propose  { background: white; color: black;}
      }
      .job {
        display: inline-block; padding: 0.1rem 0.3rem;
        font-weight: bold; text-transform: uppercase;
        background: $nikon-yellow;
      }
      .desc { margin: 0.6rem 0; line-height: 1.4; font-weight: 200; }
      .loc { font-weight: bold; line-height: 2;
        i { color: $nikon-yellow; font-size: 0.8rem; top: 0.1rem; }
      }
      .period { font-weight: bold; line-height: 2;
        i { color: $nikon-yellow; font-size: 0.8rem; top: 0.1rem; }
      }
      .link {
        a{font-weight: bold; line-height: 2;color:black;
          i { color: $nikon-yellow; font-size: 0.8rem; top: 0.1rem; padding-right:.1rem; }
        }
      }
      .paid {
        position: absolute; bottom: 1rem; left: 1rem;
        font-size: 1.1rem;color: $nikon-yellow;
      }
      .button {
        background: white; border: none; float: right;
        &:hover { background: black; }
      }
    }
  }
}

.modal-body{
  .confirmation{text-align:center;padding:3rem;}
}

form.new-annonce {
  .rgpd{font-size:.8em;margin-bottom:0;}
  input, textarea, select {
    background: #eee;
    border-radius: 0;
  }
  .form-field{margin-bottom:1rem;}
  .custom-select {
    height: auto;
    background-image:
      linear-gradient(48deg, transparent 50%, #495057 56%),
      linear-gradient(132deg, #495057 50%, transparent 56%) !important;
    background-position:
      calc(100% - 1.4em) 1em,
      calc(100% - 0.9em) 1em,
      100% 0;
    background-size: 0.5em 0.5em, 0.5em 0.5em;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}
