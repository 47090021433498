/* Éléments de base */

body.popup {
  height: auto;
}
a:active,
a:visited,
a:hover,
a:focus,
a {
  text-decoration: none;
  color: #7d7c7c;
}
.clear {
  clear: both;
}
.popin {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 1500;
  background: rgba(0, 0, 0, 0.7);
}
.popin .close {
  color: #000000;
  margin-right: 10px;
  margin-top: 9px;
  opacity: 1;
}
.iam {
  position: relative;
  line-height: 3.7rem;
  color: #fff;
  text-transform: uppercase;
  display: inline-block;
}
.iam::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 23%;
  height: 0.15em;
  background-color: #fed700;
}
button {
  outline: none;
}
.button {
  display: inline-block;
  cursor: pointer;
  background-color: $nikon-yellow;
  color: #000 !important;
  padding: 6px 10px;
  text-transform: uppercase;
  font-family: $nikon-title;
  font-weight: bold;
  border: none;
}
.button:hover {
  background-color: #000;
  color: $nikon-yellow !important;
}
.button.inverse {
  background-color: #000;
  color: $nikon-yellow !important;
  border: 1px solid #000;
}
.button.inverse:hover {
  background-color: $nikon-yellow;
  color: #000 !important;
}
.blank-button,
a.blank-button {
  display: inline-block;
  font-weight: bold;
  border: none;
  background: none;
  font-family: "Nexa";
  color: #fff;
  text-transform: uppercase;
  padding: 0;
}
.blank-button:hover {
  opacity: 0.7;
}
.message.error {
  color: #ad1a2b;
}
.message.ok {
  color: #16aa51;
}
h1 {
  text-align: center;
  font-family: "Nexa";
  font-weight: bold !important;
  color: #000;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.carousel {
  line-height: initial;
}
.carousel-control {
  position: absolute;
  filter: alpha(opacity=50);
  top: 50%;
  left: 1rem;
  width: 1rem;
  height: 2rem;
  margin-top: -1rem;
  font-size: 1rem;
  font-weight: 100;
  line-height: 1rem;
  border-radius: 0;
  opacity: 0.5;
  border: none;
}
.carousel-control:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.carousel-control.left {
  background: url("/public/img/icons/arrow-left.png") no-repeat;
}
.carousel-control.right {
  background: url("/public/img/icons/arrow-right.png") no-repeat;
}
.carousel-control.dark.left {
  background: url("/public/img/icons/arrow-left-dark.png") no-repeat;
}
.carousel-control.dark.right {
  background: url("/public/img/icons/arrow-right-dark.png") no-repeat;
}
.tab-pane {
  display: none;
  padding: 0;
  position: relative;
}
.tab-pane.active {
  display: block;
}
.btn-nikon,
.btn-nikon-yellow,
.btn-nikon-white,
.btn-nikon-white-outline,
.btn-nikon-red,
.btn-nikon-red-disabled,
.btn-nikon-black-outline {
  border: 2px solid #000;
  border-radius: 0;
  font-family: $nikon-title;
  text-shadow: none !important;
  text-transform: uppercase;
  font-weight: bold;
  background: #fff !important;
  color: #000 !important;
}
.btn-nikon-yellow.btn-xs,
.btn-xs {
  font-size: 0.6rem;
  padding: 0.2rem 0.3rem;
}
.btn-xxs {
  font-size: 0.53rem;
  padding: 0.1rem 0.2rem;
}
.btn-nikon-yellow {
  border: 2px solid $nikon-yellow;
  background: $nikon-yellow !important;
}
.btn-nikon-white {
  border: 2px solid #fff;
  background: #fff !important;
  color: #000 !important;
}
.btn-nikon-white-outline {
  border: 2px solid #fff;
  background: none !important;
  color: #fff !important;
}
.btn-nikon-black {
  border: 2px solid #000;
  background: $nikon-black !important;
  color: #fff !important;
}
.btn-nikon-black:hover {
  background: none !important;
  color: $nikon-black !important;
}
.btn-nikon-black-outline {
  border: 2px solid #000;
  background: none !important;
  color: #000 !important;
}
.btn-nikon-red {
  border: 2px solid #e52958;
  background: #ad1a2b !important;
  color: #fff !important;
}
.btn-nikon-red-disabled {
  border: 2px solid #e52958;
  background: #ad1a2b !important;
  color: #fff !important;
}

.btn-nikon-red:hover {
  background: #000 !important;
  color: #fff !important;
}
.btn-nikon:hover {
  background: #000 !important;
  color: #fed700 !important;
}
.btn-nikon-yellow:hover {
  background: #000 !important;
  color: #fed700 !important;
}
.btn-nikon-white:hover {
  background: #000 !important;
  color: #fff !important;
}
.btn-nikon-white-outline:hover {
  border: 2px solid #fff;
  background: #fff !important;
  color: #000 !important;
}
@media screen and (max-width: 650px) {
  .tab-pane {
    padding: 0;
  }
}
.span5 {
  width: 470px;
}

.mobile-only {
  display: none !important;
}
@media screen and (max-width: 650px) {
  .mobile-only {
    display: block !important;
  }
  .desktop-only {
    display: none !important;
  }
}

.medium-down {
  display: none !important;
}
@media screen and (max-width: 1000px) {
  .medium-down {
    display: block !important;
  }
  .desktop-only {
    display: none !important;
  }
}

/* Footer */
#footer {
  text-transform: uppercase;
  color: $nikon-yellow;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  background-color: $nikon-black;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  font-family: $nikon-subtitle;

  section {
    &.footer-links a {
      font-size: 0.55rem;
      padding: 0 0.7em;
      color: white;
      &:hover {
        text-decoration: underline;
      }
    }

    &.footer-social a {
      padding: 0 0.3em;
    }
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;

    section {
      padding-bottom: 1.5em;

      &.footer-logo {
        padding-top: 1em;
      }
    }
  }
}

/* Titre de section */
.section-title {
  position: relative;
  text-align: right;
}

.section-title .title {
  font-size: 0.7em;
  position: absolute;
  display: block;
  right: 0;
  top: -2em;
  position: absolute;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: bold;
  transform-origin: top right;
  transition: transform 600ms;
}
.section-title .title:after {
  content: "";
  display: inline-block;
  height: 0.8em;
  width: 4em;
  background: #fed700;
  margin-left: 0.4em;
  opacity: 0;
  transition: opacity 300ms;
  transition-delay: 600ms;
}
.section-title .title.right-title {
  transform: rotate(-90deg);
  right: 3em;
}
.section-title .title.left-title {
  transform: rotate(-90deg);
  right: 100%;
  width: 20em;
  text-align: right;
}

.section-title h1 {
  transition: transform 600ms;
}
.section-title h1.left {
  position: absolute;
  left: 1em;
  top: 1em;
  float: left;
  color: #000;
  text-align: left;
  z-index: 1;
  font-size: 2.2em;
  line-height: 1.1;
}
.section-title h1.left::before {
  position: absolute;
  right: 1rem;
  bottom: 0;
  left: 50%;
  top: 0;
  background: #fed700;
  content: "";
  z-index: -1;
}
.section-title h1.right {
  position: absolute;
  right: 2em;
  top: 2em;
  color: #fff;
  font-size: 2.4em;
}

.section-title h1.center {
  position: absolute;
  right: 0em;
  left: 0em;
  top: 2em;
  font-size: 2.4em;
}

.section-title.in-view h1.left,
.section-title.in-view h1.right {
  transform: translate(0%, 0);
}

.section-title.in-view .title.right-title,
.section-title.in-view .title.left-title {
  transform: translate(0%, 0) rotate(-90deg);
}
.section-title.in-view .title:after {
  opacity: 1;
}
@media screen and (max-width: 650px) {
  .section-title h1.left {
    float: none;
    top: 1.6rem;
  }

  .section-title h1.left,
  .section-title h1.right {
    font-size: 1.8em;
  }

  .section-title h1.center {
    font-size: 1.6em !important;
  }

  .section-title h1.left,
  .section-title h1.center {
    position: relative !important;
    text-align: center !important;
    left: auto !important;
    right: auto !important;
    margin-bottom: 1rem;
  }
}

/* Sticker réseaux sociaux en position fixe sur la droite */
#follow-us-sticker {
  position: fixed;
  right: 6px;
  z-index: 10000;
  top: 155px;
}
#follow-us-sticker a {
  display: block;
  margin-bottom: 2px;
}
#follow-us-sticker a img {
  border-radius: 50%;
}
@media screen and (max-width: 850px) {
  #follow-us-sticker {
    display: none;
  }
}

/* Petite barre "partenaires" en haut */
.top-partners {
  top: 0;
  width: 100%;
  height: 30px;
  background-color: #161616;
  text-align: center;
  text-transform: uppercase;
  font-family: "Nexa";
  z-index: 100;
  font-size: 12px;
  color: #777;
  padding-top: 3px;
}
.top-partners > div > div {
  float: left;
  text-align: center;
  margin-left: 10px;
}
@media screen and (max-width: 850px) {
  .top-partners {
    display: none;
  }
}

/* Barre présente si on usurpe un utilisateur */
.switch-bar {
  top: 0;
  width: 100%;
  height: 24px;
  background-color: #e52958;
  text-align: center;
  font-family: "Nexa";
  z-index: 100;
  font-size: 14px;
  color: #fff;
  padding-top: 3px;
  position: fixed;
}
.switch-bar a {
  color: #fff;
  text-decoration: underline;
}

/** Compte à rebours avant la phase suivante */
#countdown {
  color: #fff;
  text-align: center;
}
#countdown .data {
  font-family: "Nexa";
  margin: 0.4rem auto;
  display: flex;
  font-size: 0.6rem;
  line-height: 2.2em;
}
#countdown .data .countdown_section {
  display: block;
  text-align: center;
  width: 100%;
}
#countdown .data .countdown_section:nth-child(2) {
  width: 0.2rem;
}
#countdown .data .countdown_section:nth-child(4) {
  width: 0.2rem;
}
#countdown .data .countdown_section:nth-child(6) {
  width: 0.2rem;
}
#countdown .data .countdown_section:nth-child(8) {
  width: 0.2rem;
}
#countdown .data .countdown_section .countdown_amount {
  font-size: 1.8rem;
}
#countdown .until {
  font-size: 0.8rem;
}
@media screen and (max-width: 650px) {
}

/* Page principale (home) */
#home.container {
  width: 100%;
  overflow: hidden;
}

/* Page d'activation du compte */
#activation {
  color: #fff;
  text-align: center;
  margin-top: 180px;
}

/*** Blocks principaux (prenant toute la largeur de la page) ***/

/* Block Partenaires */
#main-block-partners {
  font-family: $nikon-title;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  background-color: #fff;
  color: #000;
  padding: 1rem 0 2rem 0;
  .container div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;

    @media screen and (max-width: 1000px) {
      display: block;
      padding-bottom: 40px;

      a {
        display: inline-block;
        margin: 0 32px;
      }
    }
  }
}
#main-block-partners h1 {
  display: inline-block;
  position: relative;
  margin: 0 0 0.5em;
  font-size: 0.9em;
  text-transform: uppercase;
  padding-left: 4rem;
  padding-right: 4rem;
  line-height: 1rem;
}
#main-block-partners h1:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.2rem;
  bottom: 0.3rem;
  width: 3.6rem;
  background: #fed700;
}
#main-block-partners h1:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0.2rem;
  bottom: 0.3rem;
  width: 3.6rem;
  background: #fed700;
}
#main-block-partners div a {
  line-height: 100px;
  flex: 1 0 18%;
  padding: 10px 21px;
}
#main-block-partners div.partners-6 a {
  flex: 1 0 12%;
}
@media screen and (max-width: 700px) {
  #main-block-partners h1 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    margin: 0 0 0.5rem 0;
  }
  #main-block-partners h1:before {
    width: 2.3rem;
  }
  #main-block-partners h1:after {
    width: 2.3rem;
  }
  #main-block-partners div a {
    margin-right: 20px;
    margin-left: 20px;
  }
}

/* Block Twitter only */
#twitter {
  background: #ffffff;
  height: 620px;
}
#twitter img.logo {
  margin-top: 40px;
}
@media screen and (max-width: 650px) {
  #twitter {
    display: none;
  }
}

/* Block Brief (uniquement pour mobile) */
#brief-mobile {
  display: none;
  background-color: #fed700;
  font-family: "Nexa";
  font-size: 16px;
  line-height: 16px;
  text-align: justify;
  padding: 10px 20px 30px 20px;
  border-bottom: 1px solid #222;
}
@media screen and (max-width: 650px) {
  #brief-mobile {
    display: block;
  }
}

@keyframes bump {
  0% {
    transform: scale(1, 1);
    opacity: 0;
  }
  20% {
    transform: scale(1, 1);
    opacity: 1;
  }
  40% {
    transform: scale(1.1, 1.1);
    opacity: 1;
  }
  60% {
    transform: scale(1, 1);
    opacity: 1;
  }
  80% {
    transform: scale(1.1, 1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

/*** Fin blocks principaux ***/

/*** ANIMATION ***/

/*

#slider .menu .filters a {transition:transform 300ms; transform: translate(32rem, 0);}
#slider .menu .filters a:nth-child(1){ transition-delay:0ms; }
#slider .menu .filters a:nth-child(2){ transition-delay:100ms; }
#slider .menu .filters a:nth-child(3){ transition-delay:200ms; }
#slider .menu .filters a:nth-child(4){ transition-delay:300ms; }
#slider .menu .filters.in-view a { transform: translate(0rem, 0); }

*/

/*
@keyframes logo-left{
  0%{ transform:translate(-10rem,0); opacity:1; }
  75%{ transform:translate(2rem,0); opacity:1; }
  100%{ transform:translate(0rem,0); opacity:1; }
}
@keyframes logo-right{
  0%{ transform:translate(10rem,0); opacity:1; }
  75%{ transform:translate(-2rem,0); opacity:1; }
  100%{ transform:translate(0rem,0); opacity:1; }
}

@keyframes rain-fall{
  0%{ transform: translate(0, -10rem); opacity:1;}
  75%{ transform: translate(0, 2rem);opacity:1;}
  100%{ transform: translate(0, 0rem);opacity:1;}
}

.iam {opacity:0;}
.iam.in-view { animation-name:rain-fall; animation-duration:400ms; animation-iteration-count:1; animation-fill-mode: forwards;opacity:1; }

#header.in-view .homelink { animation-name:logo-left; animation-duration:400ms; animation-iteration-count:1; animation-fill-mode:forwards; }
#header.in-view img.nikon-official{ animation-name:logo-right; animation-duration:400ms; animation-iteration-count:1; animation-fill-mode:forwards; }

#header .homelink { opacity:0; }
#header img.nikon-official {opacity:0; }

#main-menu .navbar .nav > li { opacity:0; }
#main-menu .navbar.in-view .nav > li { animation-name: rain-fall; animation-duration: 400ms; animation-iteration-count:1; animation-fill-mode:forwards; opacity:0;}
#main-menu .navbar.in-view .nav > li:nth-child(1) { animation-delay: 100ms; }
#main-menu .navbar.in-view .nav > li:nth-child(2) { animation-delay: 200ms; }
#main-menu .navbar.in-view .nav > li:nth-child(3) { animation-delay: 300ms; }
#main-menu .navbar.in-view .nav > li:nth-child(4) { animation-delay: 400ms; }
#main-menu .navbar.in-view .nav > li:nth-child(5) { animation-delay: 500ms; }
#main-menu .navbar.in-view .nav > li:nth-child(6) { animation-delay: 600ms; }
*/
