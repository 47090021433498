@font-face {
  font-family: 'Nexa';
  src: url('../../fonts/Nexa.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/Nexa.woff') format('woff'),
       url('../../fonts/Nexa.ttf')  format('truetype'),
       url('../../fonts/Nexa.svg#Nexa') format('svg');
}
@font-face {
  font-family: 'Nexa';
  font-weight: bold;
  src: url('../../fonts/NexaBlack.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/NexaBlack.woff') format('woff'),
       url('../../fonts/NexaBlack.ttf')  format('truetype'),
       url('../../fonts/NexaBlack.svg#NexaBlack') format('svg');
}
@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/Roboto-Regular.woff2') format('woff2'),
       url('../../fonts/Roboto-Regular.woff') format('woff'),
       url('../../fonts/Roboto-Regular.ttf')  format('truetype'),
       url('../../fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
}
@font-face{
  font-family:'AvenirNextCondensed';
  font-weight:bold;
  src: url('../../fonts/AvenirNextCondensed-Bold.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/AvenirNextCondensed-Bold.woff') format('woff'),
       url('../../fonts/AvenirNextCondensed-Bold.ttf') format('truetype'),
       url('../../fonts/AvenirNextCondensed-Bold.svg#AvenirNextCondensed-Bold') format('svg')
}
@font-face{
  font-family:'AvenirNextCondensed';
  src: url('../../fonts/AvenirNextCondensed-Regular.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/AvenirNextCondensed-Regular.woff') format('woff'),
       url('../../fonts/AvenirNextCondensed-Regular.ttf') format('truetype'),
       url('../../fonts/AvenirNextCondensed-Regular.svg#AvenirNextCondensed-Regular') format('svg')
}
