/**
 * admin styles
 **/

.nff-admin{
  .btn-yellow {
    background-color: #fdd600;
    border-color: #fdd600;
    color: #fff;
  }
  .text-yellow {
    color: #fdd600 !important;
  }
  .text-orange {
    color: #eea236;
  }

  .pull-none {
    float: none;
  }

  .dropdown ul.dropdown-menu li button {
    display: block;
    width: 100%;
    padding: 3px 20px;
    clear: both;
    color: #333;
    white-space: nowrap;
    height: 26px;
    background-color: initial;
    margin: 0;
    border: 0 none;
    border-radius: 0;

    &:hover {
      text-decoration: none;
      background-color: #e8e8e8;
    }
  }

  .table>tbody>tr>td{padding:4px;}
  .btn-sm{font-size:1em;margin:.1em;}

  /*****************************
   *           LOGIN           *
   *****************************/
  &.login-page{
    background:#d2d6de;

    .login-box{
      width:360px;margin: 7% auto;
      .login-logo{
        font-size: 1.5em; text-align: center; margin-bottom: 25px; font-weight: 300;
      }
    }
    .form-control{
      font-size: 1em;
    }
    .login-box-body{
        background: #fff; padding: 20px; border-top: 0; color: #666;
    }

    .btn{
      margin-bottom: 0; font-weight: 400; text-align: center;
        vertical-align: middle;
        -ms-touch-action: manipulation; touch-action: manipulation; cursor: pointer;
        background-image: none;
        border: 1px solid transparent; white-space: nowrap;
        padding: 6px 12px;  font-size: 1em;
        line-height: 1.42857;border-radius: 4px;
    }
    .btn-block{
      display: block;width: 100%;
    }
  }
  .form-control{font-size: 0.6rem;}
  .btn.btn-flat{
          border-radius: 0;box-shadow: none;border-width: 1px;
  }
  .btn-primary{
      color: #fff; background-color: #337ab7;border-color: #2e6da4;
  }

  .pull-right {
    display: flex;
    align-items: flex-start;
  }

  .reviewer-selection{
    margin-right: 26px;
  }

  .font-title {
    font-family: 'Nexa';
  }

  .nikon-tooltip {
    font-family: 'Roboto';
    font-size: .65rem;
  
    &.tooltip-lg .tooltip-inner {
      max-width: 300px;
    }
  
    .tooltip-inner {
      border-radius: 0;
    }
  }

  .yt-player-wrapper {
    position: relative;
    width: 100%; /* Adapte à la largeur du conteneur parent */
    padding-bottom: 56.25%; /* Ratio 16:9 (9/16 = 0.5625) */
    height: 0; /* Hauteur initiale à 0 pour activer l'effet */
  }
  
  .yt-player-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }  
}

