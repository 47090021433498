/* Page d'une vidéo */
.flex {
  display: flex;
}
#video {
  padding-top: 7em;
  background: white;
}
@media screen and (max-width: 1000px) {
  #video {
    padding-top: 4.5em;
  }
}
#video .container-fluid {
  max-width: 1200px;
}
#video.coming-soon {
  text-align: center;
  color: #fff;
  margin-top: 20px;
  font-size: 2em;
  padding: 1em;
  line-height: 2em;
}
#video #movie {
  display: flex;
  flex-flow: row wrap;
  flex-direction: row;
}
#video #movie .film {
  width: 70%;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  flex-direction: column;
  flex-grow: 1;
}

#video #movie .title {
  flex-grow: 1;
}
#video #movie .title h1 {
  text-align: center;
    font-family: "Nexa";
    font-weight: bold !important;
    color: #000;
    text-transform: uppercase;
    font-size: 2em;
    margin-bottom: 20px;
}

#video #movie-info {
  display: flex;
  .left {
    width: 70%;
    display: flex;
    flex-flow: column;
    align-items: stretch;
    flex-direction: column;
    flex-grow: 1;
  }
  .right {
    width: 30%;
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: stretch;
    flex-direction: column;
    flex-grow: 1;
    background-color: $nikon-gray-1;
    color: #000;
  }
  .title-container {
    color: #151515;
    text-align: left;
    position: relative;
    display: flex;
    justify-content: space-between;

    .stats {
      font-size: 1.2em;
      margin: 1em 2em;
      line-height: 1;
    }
    .uptitle {
      text-transform: uppercase;
      font-size: 0.9em;
      position: relative;
      display: inline-block;
      line-height: 3em;
    }
    .uptitle::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 25%;
      height: 0.2em;
      background-color: #fed700;
    }
    .title {
      text-transform: uppercase;
      font-size: 1.5em;
      margin: 0.7em 0;
      font-weight: bold;
      line-height: 1;
    }
    .prizes {
      padding: 10px;
      .price {
        max-width: 70px;
      }
    }
  }
}

#video #movie .iframe-responsive-wrapper {
  position: relative;
}
#video #movie .film .iframe-responsive-wrapper .iframe-ratio {
  display: block;
  width: 100%;
  height: auto;
}
#video #movie .film .iframe-responsive-wrapper iframe {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
#video .presentation .iam {
  font-size: 25px;
  line-height: 40px;
  float: left;
  margin-bottom: 10px;
}
#video .presentation .iam div {
  float: none;
  display: inline-block;
}
#video .presentation .summary {
  font-family: $nikon-text;
  color: #626262;
  padding-bottom: 0;
  margin-bottom: 0.7em;
}
#video .presentation .details {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
#video .presentation .details .tags {
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0;
  font-size: 0.6rem;
}
#video .presentation .details .tags a {
  text-transform: uppercase;
  margin-bottom: 0.3em;
  background-color: #000;
  color: #fff;
  font-weight: normal;
  padding: 0.1rem 0.3rem;
  display: inline-block;
  font-size: 0.8em;
}
#video .presentation .subtitles {
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 0.5em;
  font-size: 0.6rem;
  span {
    text-transform: uppercase;
    margin-bottom: 0.3em;
    background-color: $nikon-gray-1;
    color: $nikon-black;
    font-weight: normal;
    padding: 0.1rem 0.3rem;
    display: inline-block;
    font-size: 0.8em;
  }
}
#video .ecole {
  margin-left: 5px;
  i {
    color: $nikon-black;
    background: $nikon-yellow;
    padding: 0.2em;
  }
  span {
    font-family: $nikon-title;
    text-transform: uppercase;
    position: relative;
    top: 2px;
    color: $nikon-black;
    font-weight: bold;
  }
}

#video .support-container {
  text-align: center;
}
#video .support-container .support {
  font-family: $nikon-title;
  font-weight: bold;
  text-shadow: none;
  text-transform: uppercase;
  font-size: 0.7rem;
  padding: 0.4rem 0.8rem;
  background: #ff5151;
  border-radius: 0;
  border: none;
  color: #fff;
  text-align: center;
  > img,
  > span {
    vertical-align: middle;
  }
  > span {
    padding: 0 0.5em;
  }
}
#video .support-container .support:hover {
  background-color: #9f0714;
}
#video .support-container .support:disabled {
  background-color: #0d7552;
  background-image: none;
}
#video .support-container .support img {
  height: 2em;
}
#video .support-container .thanks {
  color: #bcbcbc;
  text-align: center;
  margin-bottom: 15px;
  float: right;
  line-height: 12px;
  font-size: 12px;
}
#video .presentation .classement-public {
  text-align: right;
  color: #626262;
  font-size: 1em;
  font-weight: bold;
  padding: 15px;
}
#video .social {
  font-weight: bold;
  font-family: $nikon-text !important;
  color: #626262;
  font-size: 0.9em;
  font-family: inherit;
}
#video .social > .share {
  text-align: center;
  padding: 1em 0;
  a:hover {
    opacity: 0.7;
  }
}
#video .social > .share img {
  margin-left: 0.2em;
  cursor: pointer;
}
#video #movie .others {
  background-color: $nikon-black;
  width: 30%;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  flex-direction: column;
  flex-grow: 1;
}
#video #movie-info .profile {
  padding: 1em 0;
}
#video #movie-info .profile-mobile {
  display: none;
}
#video #movie-info .director {
  display: flex;
  margin: 0.4em 1em;
  position: relative;
}
#video #movie-info .director .photo {
  width: 40%;
  height: 40%;
  margin-right: 0.6em;
  display: block;
  max-width: 100px;
}
#video #movie-info .director h4 {
  width: 60%;
  text-transform: uppercase;
  font-size: 0.9em;
  line-height: 1.1;
  position: relative;
  z-index: 1;
  margin: 0.4em 0;
  font-weight: 600;
  color: $nikon-black;
  font-family: $nikon-title;
}
#video #movie-info .director h4:before {
  position: absolute;
  content: "";
  background: $nikon-yellow;
  top: -0.1em;
  height: 1.2em;
  left: -1.4em;
  width: 10.6em;
  z-index: -1;
}
#video #movie-info .director h4 .share a {
  opacity: 1;
}
#video #movie-info .director h4 .share a:hover {
  opacity: 0.7;
}
#video #movie-info .director h4 .share img {
  width: 1.5em;
  height: 1.5em;
}
#video #movie-info .text {
  font-family: $nikon-text;
  word-wrap: break-word;
  overflow-y: auto;
  margin-top: 2em;
}
#video #movie-info .text h5 {
  display: inline-block;
  position: relative;
  margin: 0;
  font-size: 0.9em;
  text-transform: uppercase;
  padding-left: 1em;
  line-height: 1em;
  font-weight: 600;
}
#video #movie-info .text h5:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.2em;
  bottom: 0.3em;
  width: 0.8em;
  background: #fed700;
}
#video #movie-info .text p {
  line-height: 1.2;
  font-size: 0.85em;
  margin: 0.6em 1em 1.2em 1em;
  font-weight: normal;
}
#video #movie-info .text li {
  line-height: 1.2;
  font-size: 0.85em;
}
#video #movie-info .blur {
  position: absolute;
  width: 100%;
  height: 10%;
  bottom: 0;
  background-image: -moz-linear-gradient(
    bottom,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(rgba(255, 255, 255, 1)),
    to(rgba(255, 255, 255, 0))
  );
  background-image: -webkit-linear-gradient(
    bottom,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  background-image: -o-linear-gradient(
    bottom,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  background-repeat: repeat-x;
}
#video .comments-container {
  display: flex;
  background: #fff;
  margin-bottom: 1em;
}
#video .other-movies {
  display: flex;
  color: white;
  padding: 1em 3em;
}
#video .other-movies h4 {
  text-align: left;
  position: relative;
  padding: 1em 1em 0.5em;
  font-size: 1.1em;
  font-weight: 600;
}
#video .other-movies h4::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 12%;
  height: 0.5em;
  background-color: #fed700;
}
#video .comments {
  width: 100%;
  padding: 1em;
}
#video .comments .bandeau-warning {
  width: 100%;
  background: crimson;
  z-index: 10;
  text-align: center;
  padding: 0.4rem;
  color: white;
  line-height: 1.1;
  font-size: 14px;
}
#video .comments h1 {
  margin: 0;
  font-size: 0.9em;
  text-transform: uppercase;
  display: none;
}
#video .other-movies .pub .embed {
  display: block;
  margin: 0 auto 3%;
}
#video .other-movies .pub img {
  display: block;
  margin: 0 auto 3%;
}
#video .other-movies .video {
  overflow: hidden;
  background: #000;
  width: 49.5%;
  position: relative;
  display: block;
  float: left;
  margin-right: 0.5%;
  margin-bottom: 0.5%;
  cursor: pointer;
}
#video .other-movies .video .title-container {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 1em 0.8em;
}
#video .other-movies .video .title-container .title {
  position: relative;
  text-align: left;
  z-index: 1;
  color: #fff;
  padding-top: 0.4em;
  font-weight: bold;
  font-size: 0.9em;
  text-transform: uppercase;
  line-height: 1.1;
}
#video .other-movies .video .title-container .title::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 15%;
  height: 0.2em;
  background-color: #fed700;
}
#video .other-movies .video .title-container .play {
  position: absolute;
  bottom: 1em;
  right: 0.6em;
  color: #fed700;
  line-height: 2em;
  text-transform: uppercase;
}
#video .other-movies .video .title-container .play img {
  width: 2em;
  height: 2em;
}
#video .other-movies .video .title-container .play .normal {
  display: block;
}
#video .other-movies .video .title-container .play .hover {
  display: none;
}
#video .other-movies .video img.preview {
  max-width: 145%;
  margin-left: -20%;
}
#video .other-movies .video img.ecole {
  position: absolute;
  top: 10%;
  right: 5%;
  display: none;
}
#video .other-movies .video:hover .preview {
  opacity: 0.4;
}
#video .other-movies .video:hover .title-container .title {
  color: #fed700;
}
#video .other-movies .video:hover .title-container .author {
  display: block;
}
#video .other-movies .video:hover .title-container .play .normal {
  display: none;
}
#video .other-movies .video:hover .title-container .play .hover {
  display: block;
}
#video .other-movies a.see-more {
  display: block;
  float: right;
  font-family: $nikon-title;
  line-height: 1.1em;
  padding: 0.7em 2em;
  border: 2px solid transparent;
  color: #fff;
  position: relative;
  z-index: 1;
  font-weight: bold;
  text-transform: uppercase;
  margin: 1em auto 0;
}
#video .other-movies a.see-more:hover {
  background: #fed700;
  color: #000;
}
#video .other-movies a.see-more::before {
  content: "";
  position: absolute;
  border: 2px solid #fff;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}
#video .other-movies a.see-more::after {
  content: "";
  left: -1.6em;
  width: 2.7em;
  top: 0.85em;
  height: 0.8em;
  background: #fed700;
  display: block;
  position: absolute;
  z-index: -2;
}
#video .other-movies a.see-more:hover::after {
  opacity: 0;
}
#video .other-movies a.see-more:hover::before {
  border: 0 none;
}
#video .other-movies a.see-more.disabled {
  opacity: 0.5;
}
/* Popin de login / inscription quand on veut supporter une vidéo */
#video .popin.login .content {
  width: 662px;
  text-align: left;
  background-color: #fff;
  text-align: center;
  font-family: $nikon-title;
  padding-bottom: 15px;
  border: 1px solid #333;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -290px 0 0 -330px;
}
#video .popin.login .content h1 {
  display: block;
  font-size: 1.2em;
  margin: 0;
  text-align: center;
  margin-bottom: 15px;
}
#video .popin.login .content .facebook-icon {
  font-size: 30px;
}
#video .popin.login .content .concours {
  text-align: left;
  width: 100%;
  height: 271px;
  position: relative;
  margin-top: 10px;
  background-repeat: no-repeat;
}
#video .popin.login .content .concours div {
  color: #fff;
  position: absolute;
  bottom: 12px;
  margin-left: 10px;
  font-size: 0.85em;
}
#video .popin.login .content .concours div a {
  color: #fff;
  text-decoration: underline;
}
#video .popin.login .content .concours div input[type="checkbox"] {
  position: relative;
  top: -3px;
}
#video .popin.login .content .btn-nikon {
  background: #fed700;
  vertical-align: baseline;
}
#video .popin.login .content a.register {
  font-size: 14px;
}
#video .popin.login .content a.register:hover {
  text-decoration: underline;
}
#video .popin.login .content .hr {
  height: 2px;
  background-color: #b7b6ae;
  text-align: center;
  width: 80%;
  margin: 15px auto;
}
#video .popin.login .content .hr span {
  background-color: white;
  position: relative;
  top: -0.5em;
  font-size: 20px;
}
#video .popin.login .content .message {
  margin: 10px;
}
#video .popin.login .content form.ajax-login {
  margin-bottom: 10px;
}
#video .popin.login .content form.ajax-login input,
#video .popin.login .content form.ajax-login button {
  margin-top: 10px;
  height: 30px;
}
.modal.login {
  .error {
    color: red;
    font-size: 0.8em;
    border: 1px solid red;
    text-align: center;
    padding: 0.5em;
    margin-bottom: 1em;
  }
}
@media screen and (max-width: 700px) {
  #video #movie-info .profile-desktop {
    display: none;
  }
  #video #movie-info .profile-mobile {
    display: block;
  }
  #video {
    padding: 2.5rem 0 0 0;
  }
  #video .container-fluid {
    padding: 0 1rem;
  }
  #video #movie,
  #video #movie-info {
    display: block;
    .title{
      margin-top: 10px;
    }
    .left,
    .right {
      width: 100%;
    }
    .summary {
      text-align: center;
    }
  }
  #video #movie .film {
    width: 100%;
    margin-right: 0;
  }
  #video #movie-info .title-container {
    display: block;
    .title {
      font-size: 1.4em;
    }
    .stats {
      text-align: center;
      font-size: 1em;
      margin: 1.1em 0.8em;
    }
  }
  #video #movie .film .iframe-responsive-wrapper {
    margin: 0 -1rem;
  }
  #video #movie .film .presentation .details {
    display: block;
  }
  #video #movie .film .presentation .details .support-container {
    width: auto;
    margin-bottom: 1rem;
  }
  #video #movie .film .presentation .details .support-container .support {
    width: 100%;
  }
  #video #movie .others {
    width: 100%;
    margin-bottom: 1rem;
  }
  #video .comments-container {
    display: block;
  }
  #video .comments {
    width: 100%;
    box-sizing: border-box;
    border: none;
    padding: 0;
    margin: 0;
  }
  #video .other-movies {
    width: 100%;
  }
  #video .other-movies .video {
    width: 48%;
    float: left;
    margin-right: 2%;
    margin-bottom: 1.5%;
  }
}

/* Page mosaïque de films */
#series,
#films {
  padding-top: 8em;
  background: #fff;
}
#series .container-fluid,
#films .container-fluid {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

#series .menu,
#films .menu {
  margin-top: 10em;
  margin-bottom: 1em;
}

#series .menu a,
#films .menu a {
  text-transform: uppercase;
  background: #dbdbdb;
  padding: 0.4em 0.6em;
  margin-bottom: 0.5em;
  color: #000;
  font-family: $nikon-title;
  font-weight: bold;
  text-align: center;
  display: inline-block;
  margin-right: 0.6em;
  font-size: 1em;
}

#series .menu a:hover,
#series .menu a.active,
#films .menu a:hover,
#films .menu a.active {
  background: #fed700;
}

#series .menu form, 
#films .menu form {
  margin: 0;
}

#series .menu form .input-group,
#films .menu form .input-group {
  position: relative;
}

#series .menu form .input-group input,
#films .menu form .input-group input {
  outline: none !important;
  border: 1px solid #000;
  padding: 0.4em 2.5em 0.4em 0.6em;
  height: 35px;
  font-size: 1em;
  position: relative;
}

#series .menu form .input-group button,
#films .menu form .input-group button {
  border: 1px solid #000;
  font-family: $nikon-title;
  font-size: 0.65rem;
  line-height: 0.8rem;
  padding: 0 10px;
  font-weight: bold;
  height: 35px;
}

#series .menu .tags-container,
#films .menu .tags-container {
  text-align: left;
  font-size: 0.8em;
  padding-top: 1em;
}

#series .menu .tags-container .search-name,
#films .menu .tags-container .search-name {
  text-transform: uppercase;
  font-weight: bold;
}

#series .menu .tags-container .tag,
#films .menu .tags-container .tag {
  font-weight: normal;
  margin: 0 0.2em 0.2em 0;
  padding: 0.2em 0.6em;
}

#series .menu .title,
#films .menu .title {
  border-top: 1px solid #ddd;
  padding-top: 1em;
  margin-top: 1.4em;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  color: #000;
  text-transform: uppercase;
}

#series .menu .title a,
#films .menu .title a {
  padding: 0.2rem 0.3rem 0.1rem;
  font-size: 0.52rem;
  font-weight: normal;
}

#series #mosaic,
#films #mosaic {
  padding-bottom: 50px;
}

#series #mosaic #top50,
#films #mosaic #top50 {
  width: 100.5%;

  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

#series #mosaic #top50 .video,
#series #mosaic #top50 .pub,
#films #mosaic #top50 .video,
#films #mosaic #top50 .pub {
  width: 20%;
  aspect-ratio: 4 / 5;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background-color: #000;
}

#series #mosaic #top50 .video:hover .preview,
#films #mosaic #top50 .video:hover .preview {
  opacity: 0.4;
}

#series #mosaic #top50 .video .title-container,
#films #mosaic #top50 .video .title-container {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 1rem 0.8rem;
}

#series #mosaic #top50 .video .title-container .title,
#films #mosaic #top50 .video .title-container .title {
  position: relative;
  text-align: left;
  z-index: 1;
  color: #fff;
  padding-top: 0.6rem;
  font-weight: bold;
  font-size: 1.2em;
  text-transform: uppercase;
  line-height: 1.1;
}

#series #mosaic #top50 .video .title-container .title::before,
#films #mosaic #top50 .video .title-container .title::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 15%;
  height: 0.2rem;
  background-color: #fed700;
}

#series #mosaic #top50 .video .title-container .author,
#films #mosaic #top50 .video .title-container .author {
  display: block;
  opacity: 0;
  transition: transform 300ms;
  transform: translate(100%, 0);
  margin-top: 1rem;
  color: #fff;
  font-weight: bold;
  font-size: 1.2em;
  text-transform: uppercase;
  line-height: 1.1;
}

#series #mosaic #top50 .video .title-container .play,
#films #mosaic #top50 .video .title-container .play {
  position: absolute;
  bottom: 1.2em;
  right: 0.8em;
  color: #fed700;
  line-height: 3em;
  text-transform: uppercase;
}

#series #mosaic #top50 .video .title-container .play img,
#films #mosaic #top50 .video .title-container .play img {
  width: 3em;
  height: 3em;
}

#series #mosaic #top50 .video .title-container .play .normal,
#films #mosaic #top50 .video .title-container .play .normal {
  display: block;
}

#series #mosaic #top50 .video .title-container .play .hover,
#films #mosaic #top50 .video .title-container .play .hover {
  display: none;
}

#series #mosaic #top50 .video img.preview,
#films #mosaic #top50 .video img.preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 300ms;
  display: block;
}

#series #mosaic #top50 .video img.ecole,
#films #mosaic #top50 .video img.ecole {
  position: absolute;
  top: 10%;
  right: 5%;
  display: none;
}

#series #mosaic #top50 .video .rank,
#films #mosaic #top50 .video .rank {
  position: absolute;
  top: 5%;
  right: 5%;
  width: 28px;
  height: 28px;
  line-height: 28px;
  background-color: #fed700;
  color: #000;
  text-align: center;
  font-weight: bold;
  border-radius: 14px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  font-size: 1.2em;
  z-index: 10;
}

#series #mosaic #top50 .video .comments,
#films #mosaic #top50 .video .comments {
  position: absolute;
  top: 15%;
  left: 5%;
  display: none;
}

#series #mosaic #top50 .video .comments .nb_comments,
#films #mosaic #top50 .video .comments .nb_comments {
  font-size: 12px;
  color: #000;
  font-family: $nikon-title;
  font-weight: bold;
  background: #fed700;
  border-radius: 50px;
  position: absolute;
  top: -14px;
  right: -14px;
  padding: 3px 7px;
}

#series #mosaic #top50 .video .comments .nb_comments.sup100,
#films #mosaic #top50 .video .comments .nb_comments.sup100 {
  padding: 3px 4px;
}

#series #mosaic #top50 .video .comments .nb_comments.inf10,
#films #mosaic #top50 .video .comments .nb_comments.inf10 {
  padding: 3px 10px;
}

#series #mosaic #top50 .video:hover img.preview,
#films #mosaic #top50 .video:hover img.preview {
  transform: scale(1.1, 1.1);
}

#series #mosaic #top50 .video:hover .title-container .title,
#films #mosaic #top50 .video:hover .title-container .title {
  color: #fed700;
}

#series #mosaic #top50 .video:hover .title-container .author,
#films #mosaic #top50 .video:hover .title-container .author {
  opacity: 1;
  transform: translate(0%, 0);
}

#series #mosaic #top50 .video:hover .title-container .play .normal,
#films #mosaic #top50 .video:hover .title-container .play .normal {
  display: none;
}

#series #mosaic #top50 .video:hover .title-container .play .hover,
#films #mosaic #top50 .video:hover .title-container .play .hover {
  display: block;
}

#series #mosaic #see-more,
#films #mosaic #see-more {
  text-align: center;
  margin: 1rem auto;
}

#series #mosaic #see-more button,
#films #mosaic #see-more button {
  display: inline-block;
  font-family: $nikon-title;
  line-height: 1.2em;
  padding: 0.6em 2em;
  border: 2px solid transparent;
  color: #000;
  background: white;
  position: relative;
  z-index: 1;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0.1rem auto;
}

#series #mosaic #see-more button:hover,
#films #mosaic #see-more button:hover {
  background: #000;
  color: #fed700;
}

#series #mosaic #see-more button::before,
#films #mosaic #see-more button::before {
  content: "";
  position: absolute;
  border: 2px solid #000;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  z-index: -1;
}

#series #mosaic #see-more button::after,
#films #mosaic #see-more button::after {
  content: "";
  left: -1em;
  width: 2em;
  top: 0.7em;
  height: 0.8em;
  background: #fed700;
  display: block;
  position: absolute;
  z-index: -2;
}

#series #mosaic #see-more button:hover::after,
#films #mosaic #see-more button:hover::after {
  opacity: 0;
}

#series #mosaic #see-more button.disabled,
#films #mosaic #see-more button.disabled {
  opacity: 0.5;
}

#series.playlist .infos,
#films.playlist .infos {
  margin-top: 15px;
  color: #fed700;
  text-align: center;
  margin-bottom: 20px;
}

#series.playlist .infos .twitter-share-button,
#films.playlist .infos .twitter-share-button {
  vertical-align: -5px;
  margin-left: 10px;
}

#series.playlist .infos .fb-share-button,
#films.playlist .infos .fb-share-button {
  margin-right: 10px;
}

#series.playlist .delete,
#films.playlist .delete {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #fed700;
  display: block;
  margin: 5px auto 10px;
}

#series.playlist .delete:hover,
#films.playlist .delete:hover {
  text-decoration: underline;
}

.episode-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.episode-button {
  background-color: #111;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.episode-button.active {
  background-color: #ffd700; /* Couleur pour le bouton actif */
  color: black;
}

button.episode-button:focus {
  outline: none;
}

.episode-button:hover {
  background-color: #555;
}

@media screen and (max-width: 1100px) {
  #series #mosaic #top50 .video,
  #series #mosaic #top50 .pub,
  #films #mosaic #top50 .video,
  #films #mosaic #top50 .pub {
    width: 24.5%;
  }
}
@media screen and (max-width: 650px) {
  #series,
  #films {
    padding-top: 4rem;
  }

  #series .container-fluid,
  #films .container-fluid {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  #series .menu,
  #films .menu {
    width: 101%;
  }

  #series .menu a,
  #films .menu a {
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    padding: 1rem 0;
    font-size: 0.6rem;
    width: 49%;
    height: 1.5rem;
    line-height: 1rem;
    margin: 0 0 1% 1%;
  }

  #series .menu form,
  #films .menu form {
    width: 98%;
  }

  #series .menu form .input-group,
  #films .menu form .input-group {
    justify-content: center;
    margin-top: 20px;
  }

  #series .menu .tags-container,
  #films .menu .tags-container {
    display: none;
  }

  #series .menu .tags-container .tag,
  #films .menu .tags-container .tag {
    padding: 0.6em 1em;
    display: inline-block;
    width: auto;
    height: auto;
  }

  #series #mosaic #top50 .video,
  #series #mosaic #top50 .pub,
  #films #mosaic #top50 .video,
  #films #mosaic #top50 .pub {
    width: 100%;
    margin-bottom: 0.6rem;
  }

  #series #mosaic #top50 .video img.preview,
  #films #mosaic #top50 .video img.preview {
    max-width: 100%;
    margin-left: 0;
  }

  #series #mosaic #see-more button,
  #films #mosaic #see-more button {
    padding: 0.9em 3em;
  }
}
