
/* Body */
body, html { padding: 0 !important;
              margin: 0; color: #111; font-size: 24px;
              font-family: 'Nexa', sans-serif; height: 100%; }
body{font-size: 0.65rem; background-color: white;}

input[type=text]:focus, textarea:focus, select:focus {
  outline: none !important;
  box-shadow: none !important;
  -webkit-appearance: none;
}

.gray-layout, .bg-gray {
  background-color: $nikon-gray-1;
}
.bg-yellow {
  background-color: $nikon-yellow;
}
.bg-poster {
  background-image: url('/public/img/design/poster-bakground.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.bg-black {
  background-color: $nikon-black;
}
.bg-smash {
  background-color: #EB5159;
}
.bg-teasing {
  background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('/public/img/design/background-teaser-full.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.bg-nff {
  background-color: #015B59;
}
.bg-spotlights {
  background: url('/public/img/design/bg-dates.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-public {
  background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('/public/img/design/public-projection-600.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.text-black, p.text-black, .text-black a {
  color: #000 !important;
}
.text-yellow { color: $nikon-yellow; }
.btn-inverse{border-color: $nikon-yellow;font-size: 1em;padding: 0.375em 0.75em;}
.btn:focus{outline: none !important;box-shadow: none !important; }
.form-control {
  border-radius: 0;
  border: none;
  outline: none;
}
.font-title {
  font-family: $nikon-title;
}
.alert {
  border-radius: 0;
}
.responsive-iframe-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


@media screen and (min-width: 1800px) {
  .offset-xxl-3 {
      margin-left: 25%;
  }
  .col-xxl-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
  }
  .col-xxl-6 {
      flex: 0 0 50%;
      max-width: 50%;
  }
}

.container-fluid { margin: 0 auto; padding-left: 1em; padding-right: 1em; position: relative; }
.container-fluid.smaller { max-width: 1050px; }
.container-fluid.smallest { max-width: 950px; }
@media screen and (max-width: 650px) {
  .container-fluid { padding-left: 0.6em; padding-right: 0.6em; }
}

/* CMP OneTrust FIX */
#onetrust-consent-sdk {
  #onetrust-pc-sdk {
    .pc-title-container {
      float: none;
      width: auto;
      margin: 0;
      #pc-title {
        font-size: .7rem;
      }
      .pc-close-btn-container {
        margin-top: 0;
      }
    }
    .ot-button-group-parent {
      padding: 10px;
    }
    button {
      margin-bottom: 8px;
    }
  }
}

/* Utils */

.black-layer { background: url('/public/img/design/header-mask.png'); opacity: 0.6; position: absolute; top: 0; right: 0; bottom: 0; left: 0; }
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}
.error {color:red;font-size: 0.8em; border: 1px solid red; text-align: center; padding: 0.5em; margin-bottom: 1em;}
/* Header */

nav.header{
  position: absolute; top: 0; left: 0; right: 0; z-index: 100;
  margin: 0 auto;  height: 3.8rem;
  background: rgba(0, 0, 0, 0.4);
  &.dark { background: #151515; }
  .title { position: absolute; top: .5rem; z-index: 1; font-size: 1.5em;color: #fff; text-transform: uppercase;
    left:50%; transform: translate(-50%,0);margin:1em auto; width: 4rem;}
  .title::before { content:""; position: absolute; top: 0; left: 0; width: 23%; height: 0.15em; background-color: #fed700; }
  .homelink { position: absolute; top: 0.5rem; left: 1rem; z-index: 2;
    img.nikon { height: 2.8rem; }
  }
  img.nikon-official { height: 2.3rem; width: 2.3rem; position: absolute; top: 0.8rem; right: 0.8rem;}

  @media screen and (max-width: 1000px) {
    top:0;height: 2.5rem;
    .title { top:1.1em;margin:0; font-size: 1em;width: 7rem;line-height: 1.2rem;}
    .homelink {
      position: initial;
      text-align: center;
      display: block;
      padding-top: .4rem;
      img.nikon { height: 1.8rem; }
    }
    .menu-mobile-button { position: absolute; z-index: 2; right: 0; bottom: 0; top: 8px; width: 3rem; display: flex; justify-content: center; align-items: center; font-size: 1.5rem; outline: none; }
  }

  .dropdown { position: relative; display: inline-block;
    button,a.dropdown-main{padding-left: 1em; color:white; font-size:1em;
      i{margin-left: 0.2em;font-size:0.9rem;}
    }
    .dropdown-inner { display: none; position: absolute; background-color: #fff; min-width: 270px; top: 2.2em;z-index: 1;
      a { color: white; background-color:black; padding: .7em 2em .8em 1em; text-decoration: none; display: block;
        line-height: 1em; font-size:1em; overflow: hidden; height: auto;
        &:hover{background-color: $nikon-yellow; color: $nikon-black;}
      }

      &.overflow{max-height: 300px; overflow: scroll;}
    }
    &:hover {
      .dropdown-inner { display: block;}
      .dropbtn { background-color: #3e8e41;}
    }
  }
}


/* Menu principal */
#main-menu { text-transform: uppercase; padding-top: 1rem; position: absolute; bottom: 0; left: 0; right: 0;
  .navbar { margin: 0; border-radius: 0; min-height: auto; height: 3em; border: none; }
  .navbar .nav { margin: 0 auto; float: none; left: auto; flex-direction: row;}
  .navbar .nav.navbar-right {
    position: absolute; bottom: 0.7rem; right: 3.6rem; left: auto;

    @media screen and (min-width: 1000px) and (max-width: 1200px) {
      bottom: .8rem;
    }
  }
  .navbar .nav > li { float: none; display: inline-block; }
  .navbar .nav > li > a { font-weight: bold; text-shadow: none; color: #fff; padding: 1.2em 1em; font-size: .8em; line-height: .9em;}
  .navbar .nav > li > a .glyphicon { top:2px;}
  .navbar .nav > li > a:focus,
  .navbar .nav > li > a:hover,
  .navbar .nav > li.active > a {color: #000; background-color: $nikon-yellow; }
  .navbar .nav > li > a:hover .hide-on-hover { display:none; }
  .navbar .nav > li > a .show-on-hover { display:none; }
  .navbar .nav > li > a:hover img.show-on-hover { display:inline; }
  .dropdown-menu { background-color: #000; padding: 0; margin: 0; border: none; border-radius: 0; }
  .dropdown-menu > li > a { font-weight: bold; color: #fff; padding-bottom: 8px; padding-top: 8px; }
  .dropdown-menu > li > a:focus,
  .dropdown-menu > li > a:hover {color: #000; background: $nikon-yellow; }
  .navbar .nav li.dropdown.open > .dropdown-toggle,
  .navbar .nav li.dropdown.active > .dropdown-toggle,
  .navbar .nav li.dropdown.open.active > .dropdown-toggle {color: #000; background: $nikon-yellow; }
  ul.nav li.dropdown:hover > ul.dropdown-menu { display: block; }
  .navbar .nav > li > .dropdown-menu::before,
  .navbar .nav > li > .dropdown-menu::after { content : none;}
  .navbar .navbar-right {
    color: $nikon-yellow;
    align-items: end;
    justify-content: end;

    .desktop-only {
      &.language a {
        font-weight: normal;
        font-size: .4rem;
      }
      &.language.selected a {
          font-weight: bold;
      }
    }

    .button {
      padding: .6em;
      font-size: .38rem;
      margin-right: 5px;
      text-align: center;

      &.login {
        color: $nikon-black;
        border: 1px solid $nikon-yellow;
      }
      &.signin {
        color: white !important;
        background: none;
        border: 1px solid white;

        &:hover {
          color: white !important;
        }
      }

      @media screen and (min-width: 1000px) and (max-width: 1200px) {
        display: block;

        &.signin {
          margin-bottom: 5px;
        }
      }
    }
  }
  .navbar .navbar-right > li a { padding: 0 0.4em; }
  .navbar .navbar-right > li a:focus,
  .navbar .navbar-right > li a:hover { background: #000 !important; color: $nikon-yellow !important; }

  @media screen and (max-width: 1000px) {
    display: none; top: 4em; bottom: auto; background: #f0f0f0; padding: 0em 0;
    &.open { display: block; }
    .navbar {
      height: auto; text-align: left;
      .nav {
        margin: 0;
      }
    }
    .navbar .nav li { display: block; width: 100%;}
    .navbar .nav li > a { color: #222; font-size: 1.2em;
                            padding: 0.0em 0.4em 0.0em 2em; margin: 0.6em 0;
                            display: inline-block; }
    ul.nav li.dropdown > .dropdown-inner { display: none !important; }
    .navbar .nav.navbar-right {
      position: initial; width: 100%;
      margin-top: 1em;
      img {
        vertical-align: top;
      }
    }
    .navbar .nav.navbar-right > li.language { display: inline-block; }
    .navbar .navbar-right > li a:focus,
    .navbar .navbar-right > li a:hover { background: inherit !important; color: #222 !important; }
  }

}

/* Block Pub */
section.pub { background:black; text-align: center;
  img{max-width:100%;}
}

/* Block Newsletter */
section.newsletter{
  flex-direction: column;margin-left:0; margin-right:0;
  background-color: $nikon-yellow; padding : 2px 0px 20px; font-size: 1.3em; font-family: $nikon-text;
  @media screen and (max-width: 650px) {padding-right:1em;padding-left:1em;}

  .intitule {margin-bottom: 5px;text-transform: uppercase; font-family: $nikon-title; font-weight: bold; font-size: .95rem; margin-top: 1.5em; margin-bottom: 1em;text-align:center;line-height: 1.2;}

  form#newsletter-subscribe {
    text-align:center;margin-bottom:0px;
    input {
      border:none;border-radius: 0px;height: 40px;padding-left: 10px;font-size:.65rem;width:300px;
      color: $nikon-black;
      @media screen and (max-width: 650px) {width: 200px;}
    }
    button {
      border: 5px solid white;
      background-color: black;
      color: white;
      border-radius: 0px;
      text-shadow: none;
      background-image: none;
      height:40px;
      margin-left: -6px;
      line-height: 1;
      font-family: $nikon-title;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .5rem;
      display: inline;
      padding: .375rem;

      @media screen and (max-width: 650px) {
        border: 0px;
        &:hover {
          color: white;
          display: inline;
        }
      }
      @media screen and (min-width: 650px) {
        &:hover {
          color: black;
          background-color: $nikon-yellow;
          display: inline;
          padding: .375rem;
        }
      }
    }
  }

  .legalese {
    font-size: .5rem; margin-top: 2em;
    margin-bottom: 1em;
    a {
      text-decoration: underline !important;
      color: $nikon-black;
    }
  }
  .error {color:red;font-size: 0.8em;margin-top: 10px;}
  .confirm {color:green;font-size: 0.8em;margin-top: 10px; }
}

.other-questions {
  h2 {
    font-family: $nikon-title;
    text-transform: uppercase;
    font-weight: bold;
    font-size: .9rem;
  }
  .btn {
    padding: .375em .75em;
  }
  a {
    text-decoration: none;
    color: black;
    font-size: 0.7rem;
  }
}

/* Block See Also */
section.see-also-films {
  background-color: $nikon-black;
  color: #fff;
  text-align: center;
  padding: 1em 0 3em 0;

  h2 {
    text-transform: uppercase;
    font-family: $nikon-title;
    font-weight: bold;
    font-size: 1.2em;
    margin-top: 1.5em;
    margin-bottom: 2em;
    padding-left: 4rem;
    padding-right: 4rem;
    position: relative;
    display: inline-block;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0.2rem;
      bottom: 0.3rem;
      width: 3.6rem;
      background: $nikon-yellow;
      max-height: .3rem;
    }
    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0.2rem;
      bottom: 0.3rem;
      width: 3.6rem;
      background: $nikon-yellow;
      max-height: .3rem;
    }
  }

  .content {
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(100px, auto);

    .video {
      overflow: hidden;
      background: #000;
      position: relative;
      display: block;
      cursor: pointer;
      margin: 0 0.2em;
    }

    .video .title-container {
      background: rgba(0, 0, 0, 0.4);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      padding: 1em 0.8em;
      transition: background-color 300ms;
    }

    .video .title-container .title {
      position: relative;
      text-align: left;
      z-index: 1;
      color: #fff;
      padding-top: 0.6em;
      font-weight: bold;
      font-size: 1.2em;
      text-transform: uppercase;
      line-height: 1.1;
    }

    .video .title-container .title::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 15%;
      height: 0.2em;
      background-color: $nikon-yellow;
    }

    .video .title-container .author {
      display: block;
      opacity: 0;
      margin-top: 1em;
      color: #fff;
      font-weight: bold;
      font-size: 1.2em;
      text-transform: uppercase;
      line-height: 1.1;
      transition: transform 300ms;
      transform: translate(100%, 0);
    }

    .video .title-container .play {
      position: absolute;
      bottom: 1.2em;
      right: 0.8em;
      color: $nikon-yellow;
      line-height: 3em;
      text-transform: uppercase;
    }

    .video .title-container .play img {
      width: 3em;
      height: 3em;
    }

    .video .title-container .play .normal {
      display: block;
    }

    .video .title-container .play .hover {
      display: none;
    }

    .video img.preview {
      max-width: 200%;
      margin-left: -50%;
      transition: transform 300ms;
      max-height: 360px;
    }

    .video:hover .preview {
      opacity: 0.4;
    }

    .video:hover img.preview {
      transform: scale(1.1, 1.1);
    }

    .video:hover .title-container .title {
      color: $nikon-yellow;
    }

    .video:hover .title-container .author {
      opacity: 1;
      transform: translate(0%, 0);
    }

    .video:hover .title-container .play .normal {
      display: none;
    }

    .video:hover .title-container .play .hover {
      display: block;
    }
  }

  @media screen and (max-width: 700px) {
    h2 {
      font-size: .9em;
      &:before,
      &:after {
        width: 3rem;
      }
    }
    .content {
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 2em;
    }
  }
}

/* Block Suivez-nous */
section.follow-us {
  background: white;
  border-top: 1px solid #f2f2f2;
  padding: 1.5rem 0;
  text-align: center;

  a {
    margin-left: 0.44rem; margin-right: 0.44rem; display: inline-block; font-size:.9rem;
    img {
      display: block;
      transition: all 0.3s;
    }
    img:hover {
      transform: scale(1.1);
    }
  }

  h2 { display: inline-block; position: relative; margin: 0 0 .9rem 0; font-size: 0.9em; text-transform: uppercase; padding-left: 4rem; padding-right: 4rem; line-height: 1rem; font-weight: bold; }
  h2:before { content: ""; position: absolute; left: 0; top: 0.2rem; bottom: 0.3rem; width: 3.6rem; background: #fed700; }
  h2:after { content: ""; position: absolute; right: 0; top: 0.2rem; bottom: 0.3rem; width: 3.6rem; background: #fed700; }
  @media screen and (max-width: 650px) {
    border-top: none;
    a { margin-left: 0.2rem; margin-right: 0.2rem; display: inline-block; }
  }
}

/* Block Countdown */
section.countdown{
  position: relative; padding: 1rem;  text-align: center;margin-right:0;margin-left:0;
  text-transform: uppercase; color: white;
  .timer{ font-family: AvenirNextCondensed;
    .countdown_section{display: inline-block;}
    .countdown_amount{font-size: 5em; text-shadow: 1px 1px 2px rgba(0,0,0,1);
      @media screen and (max-width: 650px) {font-size:3em;}
      @media screen and (max-width: 330px) {font-size:2em;}
    }
    .legend{font-size: 1.3em;
      @media screen and (max-width: 330px) {font-size:.7em;}
    }
  }
  .until{font-size: 1.8em;
     @media screen and (max-width: 650px) { font-size: .8rem;}
  }
  .countdown-credit small {
    color: #bfbfbf;
    font-size: 0.45rem;
  }
}

/* Block Header video */
section.video{
  margin-left:0;margin-right:0;
}

/* Modals */
.modal {
  background: rgba(0,0,0,0.5);
  .modal-content {
    border-radius: 0;
    border: none;
  }
  .cancel-button {
    color: #000;
    font-size: 2em;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1000;
    &:hover {
      text-decoration: none;
    }
  }
}
.modal-dialog {
  font-size: 0.65rem;
  h2 { font-size: 1rem; }
  input, select, textarea {
    font-size: 0.65rem;
  }
}
.modal-backdrop {
  opacity: 0;
}

.modal.annonce {
  .modal-dialog {
    max-width: 900px !important;
  }
  .modal-content {
    background: $nikon-yellow;
    h1 {
      font-size: 1rem;
    }
  }
}

.modal.archive {background:rgba(0,0,0,0.8);
  .modal-dialog {
    max-width: 580px !important;
  }
  .modal-body{
    iframe{width:530px;height:300px;}
  }
  .modal-footer{
    padding: 1em;
    .btn{font-size: 0.8em;}
  }
}

#intro{
  .home-intro .countdown-container {
    a.button {
      display: block; width: auto; margin-top: 1rem; padding: 1rem;
    }
    &:before {
      content:""; position: absolute; top: 0; left: 0; width: 25%; height: 0.2rem; background-color: #fed700;
    }
    &.in-view {
      //animation-name:bump; animation-duration:600ms; animation-iteration-count:1; animation-fill-mode:forwards; animation-delay: 300ms;
    }

  }
}

.nikon-tooltip {
  font-family: $nikon-text;
  font-size: .65rem;

  &.tooltip-lg .tooltip-inner {
    max-width: 300px;
  }

  .tooltip-inner {
    border-radius: 0;
  }
}

.tarteaucitronOpenPanel{cursor:pointer;}


ui-cropper {
  width: 100%;
  display: block; }
  ui-cropper.fixed-height {
    height: 100%;
    position: relative;
    overflow: hidden; }
    ui-cropper.fixed-height canvas {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%; }
  ui-cropper.full-width {
    text-align: center; }
    ui-cropper.full-width canvas {
      display: inline; }
  ui-cropper canvas {
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    /* mobile webkit */ }
  ui-cropper .loading {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-justify-content: center;
    color: white;
    background-color: rgba(0, 0, 0, 0.75); }
    ui-cropper .loading .fixed-height {
      height: 100%;
      position: absolute; }

    /* Block News */
    section.news{ background: #fff; color: #000;
      .logo { margin-top: 20px; }
      #news-carousel { height: auto; position: relative; }
      #news-carousel .carousel-inner { width: 100%; height: auto; margin: 0; }
      #news-carousel .carousel-track { display: flex; flex-flow: row nowrap; transition: transform 400ms;}
      #news-carousel .item { width: 100%; height: 100%; min-height: 200px; padding: 0 5px; background-color: #000; font-family: $nikon-title; font-size: 0.65rem; text-align: justify; line-height: 18px; background-size: cover; background-repeat: no-repeat; background-position: center; }
      #news-carousel .carousel-indicators { margin-bottom: 2em;margin-top: 1em; bottom : 0; top: inherit; right: 0; left: 0; text-align: center;  }
      #news-carousel .carousel-indicators li { background-color: #b1b1b1; float: none; display: inline-block; cursor: pointer; width: 10px;height: 10px;border-radius: 5px; margin-left: 1em;}
      #news-carousel .carousel-indicators li.active,
      #news-carousel .carousel-indicators li:hover { background-color: #fed700; }
      #news-carousel .item .text { margin-left: 50%; margin-right:3%; padding-top: 2rem; font-family: "Nexa"; font-size: 0.65rem; text-align: justify; line-height: 18px; color: #fff; }
      #news-carousel .item .title { position: relative; text-transform: uppercase;  text-align: left;  font-size: 1.8em; font-weight: bold; line-height: 1.1em; z-index: 1; }
      #news-carousel .item .desc { margin: 1em 0; }
      #news-carousel .item a { color: #fff; }
      #news-carousel .item a:hover { color: #fed700; }
      #news-carousel .carousel-control.right { right: 0; left: auto;}
      #news-carousel:hover .carousel-control { display: block; }

      @media screen and (max-width: 650px) {
        #news-carousel { height: auto; }
        #news-carousel .item { height: auto; }
        #news-carousel .item .picture { width: 100%;  margin-left: 0;  float: none; height: auto; }
        #news-carousel .item .picture img { max-width: 100%;  max-height: 100%; margin-top: 0; }
        #news-carousel .item .text { margin: 0; }
        #news-carousel .item .text .title { width: 100%; font-size: 1.4em; }
        #news-carousel .item .text .title::after { bottom: 0; left: 40%;  }
        #news-carousel .button-container { text-align: center; }
        #news-carousel .carousel-control.left { left: 0; }
        #news-carousel .carousel-control.right { right: 0; }
      }
    }

table.privacy{
  border: 1px solid #e4e1de; border-collapse: collapse; border-spacing: 0; max-width: 100%;
  th, td {
    padding: .7em; border: 1px solid #e4e1de; font-size: 0.5rem;
  }
}
