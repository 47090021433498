#home{
  &.container-fluid{padding-left: 0; padding-right: 0;}

  .black-layer {
    background: url('/public/img/design/header-mask.png');
    opacity: 0.4;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  section{

  /* Intro de la home */
    &.intro{
      .bandeau-annonce{ position: absolute;top: 5.3rem;width: 100%;background: #fed700;z-index: 10;text-align:center;padding:.5rem;color:black;
        a{color:white; text-decoration: underline;}
      }
      .bandeau-warning{ position: absolute;top: 6rem; right:1rem;width: 25%;background: crimson;z-index: 10;text-align:center;padding:.3rem;color:white; line-height: 1.1; font-size: 14px; }
      .bandeau-privacy{ padding-left: 5rem; padding-right: 5rem;
        @media screen and (max-width: 650px) {padding-right: 1rem; padding-left: 1rem;}
      }
      .slick-slide img { display: inline; }
      .slick-dots { position: absolute; bottom: 5em; width: 100%; text-align: center; margin: 0; }
      .slick-dots li { display: inline-block; border-radius: 10em; overflow: hidden; width: 0.6em; height: 0.6em; margin: 0.2em; border: 0.1em solid #ccc; }
      .slick-dots li:hover,
      .slick-dots li.slick-active { background: #fed700; border-color: #fed700; }
      .slick-dots li button { opacity: 0; }
      .intro-slide { text-align: center; height: 60em; max-height: 100vh;
                     padding-top: 4rem;
                     background-color: black; background-position: center center;
                     background-repeat: no-repeat;
                     background-size: cover; position: relative; }
      .intro-slide.fr {background-image: url('/public/img/design/background.png');}
      .intro-slide.en {background-image: url('/public/img/design/background-en-2024.jpg');}
      .cloture-slide { text-align: center; height: 60em; max-height: 100vh;
                     padding-top: 4rem; background: url('/public/img/design/background.png');
                     background-color: black; background-position: center center;
                     background-repeat: no-repeat;
                     background-size: cover; position: relative; }
      .projection-slide {
        text-align: center;
        height: 60em;
        max-height: 100vh;
        padding-top: 4rem;
        background: url('/public/img/design/background-cta-final.jpg');
        background-color: black;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
      }
      .home-intro { display: flex; padding: 35vh 4rem; justify-content: space-between; }
      .home-intro .title-container { color: #fff; text-align: left; width: auto;  transition:opacity 400ms; opacity:1; position: relative;}
      /*.home-intro .title-container.in-view {  animation-name:bump; animation-duration:600ms; animation-iteration-count:1; animation-fill-mode:forwards; }*/
      .home-intro .title-container .uptitle { text-transform: uppercase; font-size: 0.9em; position: relative; display: inline-block; line-height: 3em; white-space: nowrap;}
      .home-intro .title-container .uptitle::before { content:""; position: absolute; bottom: 0; left: 0; width: 25%; height: 0.2em; background-color: #fed700; }
      .home-intro .title-container .title { text-transform: uppercase; font-size: 1.8em; margin: 2em 0; font-weight: bold; line-height: 1.1;}
      .home-intro .title-container .subtitle { margin-bottom: 1em; text-transform: uppercase; }
      .home-intro .countdown-container{
        position: relative;background:rgba(255,255,255,0.2);padding:1em;
        height: 100%;
        &::before{content:"";position: absolute; line-height: 2.4em; padding: 0.1em 0.4em; background: #fed700; left: 0em; top: 0;  min-height: .3em; min-width: 6em; }
        .timer{ font-family: AvenirNextCondensed; color: white;
          .countdown_section{display: inline-block;}
          .countdown_amount{font-size: 3em; text-shadow: 1px 1px 2px rgba(0,0,0,1);
            @media screen and (max-width: 650px) {font-size:1.5em;}
            @media screen and (max-width: 330px) {font-size:1em;}
          }
          .legend{font-size: 1.3em;color: white;
            @media screen and (max-width: 650px) {font-size:1.1em;}
            @media screen and (max-width: 330px) {font-size:1em;}
          }
        }
        .until{font-size: 1em; color: white;margin-bottom: 1em;text-transform:uppercase;width:90%; padding-left:5%;}
        .button{margin-top: 5px; font-size: 1.2em; color: black;}
      }
      .home-intro .credits{transform:rotate(-90deg); color:white; position:absolute; right:-1.5rem; bottom:20vh; }

      @media screen and (max-height: 600px) {
        .home-intro { padding-top: 4rem; }
      }
      @media screen and (max-width:650px) {
        .bandeau-annonce{top: 2.5rem;}
        .intro-slide { padding-top: 2rem; padding-bottom: 2rem;
        background-repeat: no-repeat;background-position: center;background-size: cover; }
        .intro-slide.fr {background-image: url('/public/img/design/background_mobile.png');}
        .intro-slide.en {background-image: url('/public/img/design/background-mobile-en-2024.jpg');}
        .home-intro { display: block; padding: 1rem 0; margin-top:4rem;}
        .home-intro .title-container {  margin: 0 1rem; padding-bottom: 1rem;
          .title{font-size: 1.3em;}
          .subtitle{font-size: 0.5rem;}
        }
        .home-intro .countdown-container { width: auto; margin: 0 2rem; max-height: 16em;
          .until{width: 95%;}
          img{max-width: 100%;}
          .button{width: 90%;}
        }
        .slick-dots { bottom: 1.5em; padding-left:0;}
      }

      .slide-item {overflow:hidden;}

      .iframe-responsive-wrapper { position: relative; max-height: 383px; }
      .iframe-responsive-wrapper .iframe-ratio { display: block; width: 100%; height: auto; }
      .iframe-responsive-wrapper iframe { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
      .home-carousel { height: 468px; background: #161616; padding-bottom: 10px; margin-bottom: 10px;
        .carousel-inner { position: absolute;}
        .prices { height: 578px;background-color: transparent;}
        .movie { width: 100%; height: 382px; background-size: cover; display: block; }
        .prices .movie { width: 100%; height: 494px; background-size: cover; display: block; }
        .movie .comments { padding-top: 10px; margin-top: 20px; }
        .movie .comments img { margin: 0 auto; width: 35px; position: static; left: 0; top: 0;}
        .movie .comments .nb_comments { font-size: 12px; position: absolute; color: #000; font-family: "Nexa"; font-weight: bold; background: #fed700; border-radius: 50px; top: 20px; right: 0px; padding: 5px 5px;}
        .movie .comments .nb_comments.sup100 { padding: 5px 2px; }
        .movie .comments .nb_comments.inf10 { padding: 5px 8px; }
        .movie .badge-ecole { margin: 0 auto; width: 24px; padding-top: 10px; margin: 20px auto 0 8px; position: static; left: 0; top: 0; }
        .movie .price { left: 80%; width: 18%; top: 3%; position: absolute;}
        .movie .top-badge { background-color: #fed500; height: 40px; width: 50px; border-radius: 50px; color: #000; text-transform: uppercase; margin: 0 auto; font-family: "Nexa"; font-weight: bold; text-align: center; font-size:1rem; padding-top: 10px; margin-top: 20px; }
        .movie .infos { position: absolute; width: 100%; height: 90px; bottom: 0; background-color: rgb(0, 0, 0); background-color: rgba(0, 0, 0, 0.7); filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000); -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000)"; text-align: center; color: #fff; font-family: "Nexa"; font-weight: bold; text-transform: uppercase; font-size:1rem; }
        .movie .badges { position: absolute; width: 6%; height: 100%; right: 5%; }
        .movie .infos .subtitle { margin-top: 10px; }
        .prices .movie .infos .subtitle { color: #fed700; font-size: 18px; }
        .movie .infos .iam { font-size: 26px; line-height: 30px; margin-top:1rem; margin-bottom: 10px; }
        .movie img { position: absolute; left: 45%; top: 40%; }
        .carousel-indicators { width: 100%; bottom: 0; right : 0; top: auto; margin-bottom: 10px;margin:0;}
        .carousel-indicators li { width: 18.3%; width: 18%\9; margin-right: 1%; background-size: cover; height: 78px; border-radius: 0; -webkit-border-radius: 0; -moz-border-radius: 0; opacity: 0.7; position: relative; }
        .prices .carousel-indicators li { width: 12.3%; background-size: contain; background-repeat: no-repeat; height: 62px; background-color: transparent; opacity: 1}
        .prices .carousel-indicators li.price:hover,
        .prices .carousel-indicators li.price.active { filter: grayscale(1) brightness(2); -webkit-filter: grayscale(1) brightness(2); }
        .prices .carousel-indicators li:first-child{margin-left: 2.5%;}
        .carousel-indicators li:hover,
        .carousel-indicators li.active { cursor : pointer; opacity: 1; }
        .carousel-indicators li:last-child { margin-right: 0; }
        .carousel-indicators li div.title { position: absolute; text-indent: 0px; width: 100%; margin: 0 auto; white-space: nowrap; bottom: 5%; display: none; overflow: hidden; }
        .carousel-indicators li:not(.active):hover div.title { display: block; }
        .carousel-indicators li div.title .iam { overflow: hidden; text-overflow: ellipsis; text-align: left; max-width: 90%; }
        .carousel-control { border-radius : 0; -webkit-border-radius: 0; -moz-border-radius: 0; border: none; background: transparent; opacity: 1; left: -50px; font-size: 22px; height: 65px; line-height: 65px; width: 50px; }
        .carousel-control.right { right: -50px; left: auto; }
      }
      .film-menu { float: left; margin-left: 5px; width: 18.3%; height: 40px; background-color: #333333; margin-right: 1%; font-family: "Nexa"; font-weight: bold; color: #fff; text-transform: uppercase; line-height: 40px; font-size: 0.9rem; }
      .film-menu:visited { color: #fff; }
      .film-menu:hover,
      #film-search button:hover { color: #000; background-color: #fed700; cursor: pointer; }
      #film-search { width: 38.8%; float: left; height: 40px; margin-left: 5px; font-family: "Nexa"; font-weight: bold; font-size:1rem; background-color: #333333; }
      #film-search .input-group { background-color: #ffd500; }
      #film-search input { outline: 0; width: 77%; border: none; height: 40px; padding: 0; padding-left: 3%; background-color: #ffffff; color: #000; font-family: "Nexa"; font-weight: bold; font-size:1rem; text-transform: uppercase; }
      #film-search input::placeholder{color: #000000 !important; opacity: 1 !important;}
      #film-search button { width: 18%; border: 0; height: 40px; padding: 0; background-color: #d9b500; color: #000; font-family: "Nexa"; font-weight: bold; font-size:1rem; }
      .share.classic { margin-top:0px; margin-bottom: 2.7em;float:right; width: 50%; padding-top: 20px; }
      .share.fin { height: 78px; margin-top: 20px; }
      .share.prices { height: 78px; }
      .share .facebook { float:left; width:50%; position:relative;  height: 100%;}
      .share .facebook > div { position: absolute; right: 8px; top: 1px; bottom: 0; }
      .share .facebook > div .fb-like { position: absolute; bottom: 0; right: 10%;}
      .share.fin .facebook > div .fb-like,
      .share.prices .facebook > div .fb-like { float:left; }
      .share .twitter {position: relative; height: 100%; min-height: 20px; width: 50%; }
      .share .twitter iframe { position: absolute !important; bottom: 0; }
      .goodbye {color: #fff; font-family: "Nexa"; font-size: 1.7em; line-height: 1.7em; margin: 1em; text-transform: uppercase;}
      .small-carousel { background-color: transparent !important; padding-top: 0; }
      .small-carousel .container-fluid {max-width: 940px !important; }
      .small-carousel .title { color: #000; font-size: 20px; padding: 10px; text-transform: uppercase; }
      .small-carousel .carousel-container { background-color: #fed700; margin-top: 20px; margin-bottom: 20px; margin-bottom: 0; }
      .small-carousel .carousel { margin-bottom: 30px; color: #000; background-color: #fed700 !important; }
      .small-carousel .carousel .carousel-header { text-transform: uppercase; font-family: "Nexa"; font-weight: bold; font-size: 19px; margin-top: 20px; }
      .small-carousel .carousel .carousel-inner { margin-top: 20px; font-size:1rem; }
      .small-carousel .carousel .carousel-control { background: none repeat scroll 0 0 #000000; border: medium none; -webkit-border-radius: 0px; -moz-border-radius: 0px; border-radius: 0px; margin-top: 20px; opacity: 1; font-size: 44px; height: 90px; width: 50px; line-height: 90px; left: -80px; top: 38%; }
      .small-carousel .carousel .carousel-control.right { right: -80px; left: auto; }
      .small-carousel .carousel .carousel-inner .item .video { width: 300px; height: 168px; float: left; margin-right: 20px; position: relative; display: block; background-size: cover; }
      .small-carousel .carousel .carousel-inner .item .video img.play { position: absolute; top: 45px; left: 105px; }
      .small-carousel .carousel .carousel-inner .item .video:hover img.play { display: none; }
      .small-carousel .carousel .carousel-inner .item .video .iam { display: none; position: relative; top: 50px; }
      .small-carousel .carousel .carousel-inner .item .video .author { display: none; position: relative; top: 70px; color: #fff; text-transform: uppercase; font-family: "Nexa"; font-weight: bold; }
      .small-carousel .carousel .carousel-inner .item .video:hover .iam { display: inline-block; }
      .small-carousel .carousel .carousel-inner .item .video:hover .author { display: block; }
      .small-carousel .carousel .carousel-inner .item .video:last-child { margin-right: 0px; }
      .tags { color: #fed700; text-transform: uppercase; margin-bottom: 10px; margin-top: 15px; }
      .tags a { background-color: #fed700; color: #000; padding: 2px 4px; margin-bottom: 4px; display: inline-block;}
      @media screen and (max-width:1100px) {
        #home-carousel .carousel-control { left: 20px; }
        #home-carousel .carousel-control.right { left: auto; right: 20px; }
      }
      @media screen and (max-width:850px) {
        #home-carousel {height : 500px;}
        #home-carousel .movie { height: 408px; }
        #home-carousel .movie img {top: 110px; left: 316px; }
        #home-carousel .carousel-indicators li { width: 18.2%; height: 80px; }
        .film-menu {width: 18.2%;}
        .small-carousel .carousel:not(.mobile) { display: none; }
        .small-carousel .carousel.mobile { display: block !important;}
        .small-carousel .carousel.mobile .carousel-inner .item .video { width: 200px; height: 113px; float: none; margin: 0 auto; }
        .small-carousel .carousel.mobile .carousel-inner .item .video .iam { display: inline-block; font-size: 0.9rem; line-height: 20px; top: 20px }
        .small-carousel .carousel.mobile .carousel-inner .item .video .author { display: block; top: 40%; }
        .small-carousel .carousel.mobile .carousel-control { left: 0; width: 30px; font-size: 26px; top: 38%; }
        .small-carousel .carousel.mobile .carousel-control.right { right: 0;  left: auto; }
      }
      @media screen and (max-width:650px) {
        #home-carousel {height : 388px; }
        #home-carousel .carousel-indicators li { width: 18%;height: 56px;}
        #home-carousel .movie { height: 316px;}
        #home-carousel .movie .badges { display: none; }
        #home-carousel .movie img {top: 44px; left: 228px; }
        .share { width: 100% !important; padding-top: 0 !important; }
        .film-menu {  width: 18%; font-size: 10px;  margin-bottom: 3%; }
        #film-search { width: 37.8%; }
        #film-search input {width: 77%; }
      }
      @media screen and (max-width:500px) {
        #home-carousel { height : 234px; }
        #home-carousel .carousel-indicators { display: none; }
        #home-carousel .movie { height: 246px; }
        #home-carousel .movie .infos { font-size: 12px; height: 90px !important; }
        #home-carousel .movie .infos .iam { font-size: 0.9rem; margin-top: 9px; margin-bottom: 9px;}
        #home-carousel .movie img { display: none; }
        #film-search { display: none; }
        .film-menu { width: 30%; }
      }
      @media screen and (max-width:450px) {
        #home-carousel { height: 185px; }
        #home-carousel .movie { height: 200px; }
      }
    }

    /* Block selection */

    &.home-selection{
      .menu { text-align: right; }
      .menu form { float: right; margin: 3em 0 1em 0; }
      .menu form button { padding-top: 0.28rem; padding-left: 0.22rem; }
      .menu form .input-group { position: relative; }
      .menu form .input-group input { border: 1px solid #000; padding: 0.4em 2.5em 0.4em 0.6em; font-size: 1em; position: relative; }
      /*.menu form .input-group:after { content:""; position: absolute; top: 0.2em; bottom: 0.2em; right: 0; padding: 0.3em 0.5em; font-size: 1.1em; border-left: 1px solid #000; }*/
      .menu .filters { float:right; margin-bottom: 2em; }
      .menu .filters a { text-transform: uppercase; background: #dbdbdb; padding: 0.4em 0.6em; color: #000; font-family: "Nexa"; font-weight: bold; text-align: center; display: inline-block; margin-left: 0.6em; margin-top:.5em; font-size: 1em; }
      .menu .filters a:hover,
      .menu .filters a.active { background: #fed700; }
      .menu .tags-container { text-align: left; font-size: 0.8em; padding-top: 1em; }
      .menu .tags-container .search-name { text-transform: uppercase; font-weight: bold;}
      .menu .tags-container .tag { font-weight: normal; margin: 0 0.2em 0.2em 0; padding: 0.2em 0.6em;  }
      .menu .title { border-top: 1px solid #ddd; padding-top: 1em; margin-top: 1.4em; font-size: 1.2em; font-weight: bold; text-align: center; color: #000; text-transform: uppercase; }
      .selection-carousel {
          position: relative; margin-bottom: 3em;
        .video { overflow: hidden; background: #000; position: relative; display: block; cursor: pointer; margin: 0 0.2em; }
        .video .title-container { background: rgba(0,0,0,0.4); position: absolute; top: 0; left: 0; bottom: 0; right: 0; padding: 1em 0.8em; transition:background-color 300ms;}
        .video .title-container .title { position: relative; text-align: left; z-index: 1; color: #fff; padding-top: 0.6em; font-weight: bold; font-size: 1.2em; text-transform: uppercase; line-height: 1.1; }
        .video .title-container .title::before { content:""; position: absolute; top: 0; left: 0; width: 15%; height: 0.2em; background-color: #fed700; }
        .video .title-container .author { display: block; opacity:0; margin-top: 1em; color: #fff; font-weight: bold; font-size: 1.2em; text-transform: uppercase; line-height: 1.1; transition:transform 300ms; transform: translate(100%,0); }
        .video .title-container .play { position: absolute; bottom: 1.2em; right: 0.8em; color: #fed700; line-height: 3em; text-transform: uppercase; }
        .video .title-container .play img { width: 3em; height: 3em; }
        .video .title-container .play .normal { display: block; }
        .video .title-container .play .hover { display: none; }
        .video img.preview { max-width: 200%;  margin-left: 0%; transition: transform 300ms; max-height: 360px; }
        .video:hover .preview { opacity: 0.4; }
        .video:hover img.preview { transform:scale(1.1, 1.1); }
        .video:hover .title-container .title { color: #fed700; }
        .video:hover .title-container .author { opacity:1; transform:translate(0%, 0); }
        .video:hover .title-container .play .normal { display: none; }
        .video:hover .title-container .play .hover { display: block; }
      }
      .slick-arrow { position: absolute; filter:alpha(opacity=50);
                    top: 50%; width: 1em; height: 2em; margin-top: -1em;
                    font-size: 1em; font-weight: 100; line-height: 1em;
                     border-radius: 0; opacity: .5; border: none; z-index: 3; }
      .slick-arrow:hover { opacity: 1; filter:alpha(opacity=100); }
      .slick-arrow.slick-prev { background:url('/public/img/icons/arrow-left.png') no-repeat; left: 1em; }
      .slick-arrow.slick-next { background:url('/public/img/icons/arrow-right.png') no-repeat; right: 1em;}
      .slick-arrow.slick-next:before{content:none;}
      .slick-arrow.slick-prev:before{content:none;}
      .slick-dots { position: absolute; margin: 1em 0; bottom: -3em; padding: 0; }
      .slick-dots li { line-height: 0; display: inline-block; width: 2%; margin:0;}
      .slick-dots li button { overflow: hidden; background: #ccc; color: #ccc; height: 0.2em; width: 100%; border: none; border-radius:0; /* override for new mac chrome user stylesheet */}
      .slick-dots li button:before{content:none;}
      .slick-dots li.slick-active button { background: #fed700; color: #fed700; }
      .slick-dots li.slick-active button:before{content:none;}
      a.see-all { display: inline-block; font-family: "Nexa"; line-height: 1.2em; padding: 0.7em 2em; border: 2px solid transparent; color: #000; background: white; position: relative; z-index: 1; font-weight: bold; text-transform: uppercase; margin-bottom: 2em; transition:transform 600ms; transform:scale(0,0); }
      a.see-all.in-view {transform:scale(1,1); }
      a.see-all:hover { background: #000; color: #fed700; }
      a.see-all::before { content: ""; position: absolute; border: 2px solid #000; top: 0; right: 0; bottom: 0; left: 0; z-index: -1; }
      a.see-all::after { content: ""; left: -1em; width: 2em; top: 0.9em; height: 0.8em; background: #fed700; display: block; position: absolute; z-index: -2; }
      a.see-all:hover::after { opacity: 0; }
      a.see-all.disabled { opacity: 0.5; }
      @media screen and (max-width: 650px) {
        .section-title h1.left{top: -3.7rem;}
        .slick-dots { left: 0; right: 0; }
        .menu { margin-top: 6em; }
        .menu .filters { display: none; }
        .menu form { display: none; }
        .menu form .input-group input { width: 100%; box-sizing: border-box; }
      }

    }

    /* Block Participez */
    &.participate {
      color: #fff; font-family: "Nexa"; text-align: center; position: relative;
     .tab-pane { padding-top: 4em; }
     #participez-dates.tab-pane {padding-top: 6em !important ;}
     #participez-dates .container-fluid.smallest{max-width: 800px;}
     .carousel { margin: 0; }
     .item { padding: 6em 3em 3em 3em; box-sizing: border-box; width: 100%; font-size:1em;  }
     #participez-brief .item,  #participez-qui .item,  #participez-inscription .item { padding-top: 6em; font-size:1em; }
     #participez-brief .item .frame,  #participez-qui .item .frame,  #participez-inscription .item .frame { height: 21em; }
     #participez-brief .item .frame {padding:2em;}
     #participez-dates .item .frame{
      .calendar-cols {
        .calendar-col {
          @media screen and (min-width:992px) {
            border-right: 1px solid gray;
            &:last-child {
              border-right: none;
            }
          }

          h3 {
            font-family: $nikon-title;
            font-size: .85rem;
            font-weight: 600;
            text-transform: uppercase;
          }

          p {
            text-transform: uppercase;
            font-size: .55rem;

            .button {
              font-size: .5rem;
              margin-top: .5rem;
            }
          }
        }
      }
    }
     .item .date { position: absolute; text-align: left; width: 8em;height: 8em; left: 0; top: 0; color: #000; background: #fed700;  padding: 0.4em; text-transform: uppercase; font-weight: bold; line-height: 1.2; }
     .item .date strong { font-size: 1.8em; }
     .frame { position: relative; border: 0.3em solid white; padding: 3em; text-align: left; display: flex; flex-direction: column; line-height: 1.2em; }
     .frame h2 { margin: 0; text-transform: uppercase; line-height: 1.2; font-size: 1.5em; font-weight:600;}
     .frame .text { color: #ccc; padding-top: .5rem; }
     .frame .text a:not(.button) { color: #ccc; text-decoration: underline; }
     .arrow-combo { background: #fed700; position: absolute; bottom: 8em; right: 1.6em; cursor: pointer; }
     .arrow-combo a { display: inline-block; line-height: 1.2em; width: 1.2em; font-size: 2em; color: #000; opacity: 0.5; }
     .arrow-combo a:hover { opacity: 1; }
     ul.submenu { list-style: none; display: block; margin: 0 auto; font-size: 0.9em; max-width: 650px; padding-bottom: 2rem; }
     ul.submenu li { position: relative; display: inline-block; width: 19%; text-align: center; text-transform: uppercase; font-family : "Nexa"; font-weight: bold; vertical-align: top; line-height: 1.2em; }
     ul.submenu li::before { content: ""; position: absolute; right: 50%; margin-right: 0.4rem; top: 0.35rem; left: 0; height: 1px; background-color: #fff; }
     ul.submenu li::after { content: ""; position: absolute; left: 50%; margin-left: 0.4em; top: 0.35rem; right: 0; height: 1px; background-color: #fff; }
     ul.submenu li:first-child::before { display: none; }
     ul.submenu li:last-child::after { display: none; }
     ul.submenu li a { position: relative; display: block; color: #fff; padding-top: 1rem; }
     ul.submenu li a::before { content: ""; position: absolute; left: 50%; top: 0; height: 0.6rem; width: 0.6rem; border-radius: 10rem; border: 0.1rem solid #fff; margin-left: -0.4rem; }
     ul.submenu li.active a,
     ul.submenu li a:hover { color: #fed700; }
     ul.submenu li a:hover::before,
     ul.submenu li.active a::before { border: 0.1rem solid #fed700; background: #fed700; }
     .participate-button-container { text-align: right; }

      .button.participate { opacity:0;}
      .button.participate.in-view {  animation-name:bump; animation-duration:600ms; animation-iteration-count:1; animation-fill-mode:forwards; animation-delay: 300ms; }

      @media screen and (max-width:650px) {
         .tab-pane { padding-top: 8em; }
         .frame h2  { font-size: 1.6em; }
         .item { padding-left: 0; padding-right: 0; padding-bottom: 0;}
         .item .frame { height: 20em; padding:1.5em;}
         .item .date {height:7em; width:7em;}
         #participez-brief .item,  #participez-qui .item,
         #participez-inscription .item { padding-top: 4em;}
         #participez-brief .item .frame,
         #participez-qui .item .frame,
         #participez-inscription .item .frame { height: auto; font-size: 0.8em; }
         #participez-dates .item .frame{ height: 100%; min-height: 11rem; }
         .carousel-control { display: none; }
         .arrow-combo { bottom: 0.8em; right: 50%; margin-right: -2.4em; }
         .arrow-combo a { line-height: 1.4em; width: 1.4em; }
         ul.submenu{padding-inline-start:0 ;}
         ul.submenu li { width: 24%; font-size: 0.7em; margin-top: 3rem;}
         .participate-button-container { text-align: center; padding-bottom: 1rem; }
      }
    }

    /* Inter homepage */
    .timeline {
      .frame {
        position: relative;
        border: 0.3em solid white;
        padding: 3em;
        text-align: left;
        display: flex;
        flex-direction: column;
        line-height: 1.2em;

        .text {
          color: #ccc;
          padding-top: .5rem;
        }

        .calendar-cols {
          .calendar-col {
            @media screen and (min-width:992px) {
              border-right: 1px solid gray;

              &:last-child {
                border-right: none;
              }
            }

            h3 {
              font-family: $nikon-title;
              font-size: .85rem;
              font-weight: 600;
              text-transform: uppercase;
            }

            p {
              text-transform: uppercase;
              font-size: .55rem;

              .button {
                font-size: .5rem;
                margin-top: .5rem;
              }
            }
          }
        }
      }
    }

    /* Bloc inter */
    &.inter-block {
      .container {
        p {
          font-size: 1.2em;
        }
        h1 {
          font-size: 2rem;
        }
        h2 {
          font-size: 1.5rem;
        }

        @media screen and (max-width: 1000px) {
          h1 {
            font-size: 1.8rem;
          }
          h2 {
            font-size: 1.2rem;
          }
          .timeline {
            flex-flow: column;
          }
        }
      }
    }

    /* Block Prix */
    &.prices{
      background-color: #000;
      position: relative; color: #fff;
      h1 { color: #fff; font-size: 1rem; padding-left: 4rem; padding-right: 4rem; display: inline-block; position: relative; margin: 0 0 .9rem 0;  }
      h1:before { content: ""; position: absolute; left: 0; top: 0.2rem; bottom: 0.3rem; width: 3.6rem; background: $nikon-yellow; }
      h1:after { content: ""; position: absolute; right: 0; top: 0.2rem; bottom: 0.3rem; width: 3.6rem; background: $nikon-yellow; }
      h3 {
        font-family: $nikon-title;
        font-weight: bold;
        font-size: .6rem;
        text-transform: uppercase;
      }
      .tab-pane { padding: 8px; font-family : $nikon-title; font-size: 1rem; text-align: justify; color: #fff; }
      #prices-carousel { width: 100%; margin: 10px auto 0; }
      #prices-carousel .carousel-control.right{right: 1em; left: auto;}
      #prices-carousel .carousel-inner { width: 100%; height: auto; }
      #prices-carousel .carousel-inner .item { width: 100%; height: 100%; }
      #prices-carousel .carousel-inner .item .popin { position: absolute; top: 2rem; left: 0; bottom: 0; right: 0; background: #fed700; color: #111; padding: 30px; text-align: left; }
      #prices-carousel .carousel-inner .item .popin a { color: #000; text-decoration: underline; }
      #prices-carousel .carousel-inner .item .popin a.close-button { display: block; top: 20px; right: 20px; position: absolute; color: #111; font-size: 1rem; text-decoration: none; }
      #prices-carousel .carousel-inner .item .popin .title { font-family: 'Nexa'; font-size: 2em; font-weight: 700; text-transform: uppercase; line-height: 50px; }
      #prices-carousel .carousel-inner .item .popin .text { margin-top: 4px; line-height: 18px; font-size: 0.9em; }
      #prices-carousel .carousel-inner .price-container { height: 430px; text-transform: uppercase; margin-bottom: 1rem; list-style: none; margin-left: 0; padding-left: 0; display: flex; display: -webkit-flex; flex-direction: row; -webkit-flex-direction: row; align-content: stretch; -webkit-align-content: stretch; }
      #prices-carousel .carousel-inner .price-container .two-col { width: 100%; height:100%; max-width: 900px; margin: 1em auto; display: flex; display: -webkit-flex; flex-direction: row; -webkit-flex-direction: row; align-content: stretch; -webkit-align-content: stretch; justify-content: stretch;}
      #prices-carousel .carousel-inner .price-container .one-col { width: 100%;  height:100%; max-width: 400px; margin: 1em auto; display: flex; display: -webkit-flex; flex-direction: row; -webkit-flex-direction: row; align-content: stretch; -webkit-align-content: stretch; justify-content: stretch;}
      #prices-carousel .carousel-inner .price-container .col { width:100%; height:100%; margin-left:1%; display: flex; display: -webkit-flex; flex-direction: column; -webkit-flex-direction: column; align-content: stretch; -webkit-align-content: stretch; justify-content: stretch;}
      #prices-carousel .carousel-inner .price-container .col .line { line-height: 1.4em; flex-grow:1; flex-basis:50%; margin-top:4%; border: 0.3em solid white; position: relative; padding: 1em; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; text-align: left; }
      #prices-carousel .carousel-inner .line-border { border: 0.3em solid white; }
      #prices-carousel .carousel-inner .price-container .col .line > .content { position: relative; margin: 0 auto; }
      #prices-carousel .carousel-inner .price-container .col .line p { margin: 0.5em 0; position: relative; font-size: .52rem; }
      #prices-carousel .carousel-inner .price-container .col:nth-child(2) .line p { font-size: .45rem; }
      #prices-carousel .carousel-inner .price-container .col .line p small { text-transform: none !important;  }
      #prices-carousel .carousel-inner .price-container .col .line p.main strong { font-size: 2em; display: block; margin-bottom: 0.4em; }
      #prices-carousel .carousel-inner .price-container .col .line p.arrow:before { content: ">"; color: #fed700; font-size: 0.9em; margin-right: 0.2em; }
      #prices-carousel .carousel-inner .price-container .col .line p.plus:before { content: "+"; color: #fed700; font-weight:900;font-size:1em; margin-right: 0.2em; }
      #prices-carousel .carousel-indicators { width: 95%; text-align: center; position: initial; margin: 0 auto;}
      #prices-carousel .carousel-indicators li {
        width: 110px; height: 68px;
        background-color: transparent;
        margin-right:8px;
        background-size: cover;
        background-repeat: no-repeat;
        float: none;
        display: inline-block;
        transform: scale(0,0);
        transition: transform 500ms;
        background-size: contain;
        cursor: default;
        filter: grayscale(1); -webkit-filter: grayscale(1);
        opacity: 1;
      }
      #prices-carousel .carousel-indicators li:nth-child(1) { transition-delay: 200ms;}
      #prices-carousel .carousel-indicators li:nth-child(2) { transition-delay: 400ms;}
      #prices-carousel .carousel-indicators li:nth-child(3) { transition-delay: 600ms;}
      #prices-carousel .carousel-indicators li:nth-child(4) { transition-delay: 800ms;}
      #prices-carousel .carousel-indicators li:nth-child(5) { transition-delay: 1000ms;}
      #prices-carousel .carousel-indicators li:nth-child(6) { transition-delay: 1200ms;}
      #prices-carousel .carousel-indicators li:nth-child(7) { transition-delay: 1400ms;}
      #prices-carousel .carousel-indicators li:nth-child(8) { transition-delay: 1600ms;}
      #prices-carousel .carousel-indicators li:nth-child(9) { transition-delay: 1800ms;}
      #prices-carousel .carousel-indicators li:nth-child(10) { transition-delay: 2000ms;}
      #prices-carousel .carousel-indicators li:nth-child(11) { transition-delay: 2200ms;}
      #prices-carousel .carousel-indicators.in-view li { transform:scale(1,1);}
      #prices-carousel .carousel-indicators:first-child li:first-child { width: 150px; height: 92px; }
      #prices-carousel .carousel-indicators li:hover,
      #prices-carousel .carousel-indicators li.active { filter: grayscale(0); -webkit-filter: grayscale(0) }
      #prices-carousel .price-image { opacity:1; }
      &.in-view #prices-carousel .price-image {  animation-name:bump; animation-duration:800ms; animation-iteration-count:1; animation-fill-mode:forwards; animation-delay: 400ms; }
      @media screen and (min-width: 971px) and (max-width: 1275px) {
        #prices-carousel .carousel-indicators li { width: 90px; height: 56px; margin-right:4px;  }
      }
      @media screen and (min-width: 651px) and (max-width: 970px) {
      }
      @media screen and (max-width: 650px) {
        #prices-carousel .container-fluid { padding-top: 40px; }
        #prices-carousel .container-fluid h1 { font-size: .8rem; padding: 0 2.5rem; }
        #prices-carousel .container-fluid h1:before, #prices-carousel .container-fluid h1:after { width: 2rem; }
        #prices-carousel .item .popin .title { font-size: 30px; line-height: 0.9rem; }
        #prices-carousel .item .popin .text { line-height: 12px; font-size: 11px; }
        #prices-carousel .item .popin a.close-button { font-size: 1rem; }
        #prices-carousel { width: auto; margin: 0 1.2rem; }
        #prices-carousel .carousel-inner .price-container .two-col { display: block; flex-direction: column; height: auto; }
        #prices-carousel .carousel-inner .price-container .one-col { display: block; flex-direction: column; height: auto; }
        #prices-carousel .carousel-indicators { display: none; }
        a.carousel-control.left { left: -0.5rem; }
        #prices-carousel .carousel-control.right { right: -0.5rem; }
      }

      @media screen and (max-width: 999px) {
        #prices-carousel .carousel-inner .price-container { display: block; flex-direction: column; height: auto; }
        #prices-carousel .carousel-inner .price-container .col { display: block; width: auto; margin-left: 1em; margin-right: 1em; height: auto; }
      }

      .brutx {
        & > div { border: 0.3em solid white; }
        h3 {
          font-size: 1rem;
          img { padding-bottom: .7rem; }
        }
        p { font-family: $nikon-text; }
        .btn { font-size: .65rem; color: $nikon-black; }
        video { max-width: 300px; }
      }
    }

    &.finalists{
      background-color: #ffd500;

      .flex-wrapper { display: flex; }
      .text-wrapper p, .logo-wrapper { padding: 1.5em; }
      .logo-wrapper img { height: 4em; }
      .text-wrapper p {  text-align:center; max-width: 900px; margin: 0 auto;}

      @media screen and (max-width:650px) {
        .flex-wrapper { flex-flow: column nowrap; }
        .logo-wrapper { text-align: center; }
      }
    }

    &.cnc{
      background-color: #fff;text-align: center;
      img{max-width:100%;}
    }

    /* Block Jury */
    &.jury {
      .jury-president-only {
        p {
          font-size: .55rem;
        }
      }
      background-color: #ededed; text-align: left;
      h2 { font-size: 1.1rem; }
      h1 { text-align: left; line-height: 1em; display: inline-block; z-index: 1; }

      h1::before { display:none;}
      h1::after { position: absolute; right: -2em; bottom: 0em; left: 50%; top: 50%; background: #fed700; content: ""; z-index: -1; }
      .jury-members {
        padding-bottom: 4em;
        padding-top: 4em;
        margin: 4em -1em 0 -1em;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }
      .jury-member {
        flex: 0 1 20%;
        box-sizing: border-box;
        padding: 0 1em;
        margin-bottom: 3em;
        display: block;
        position: relative;
        opacity:0;
        transition: opacity 200ms;
      }
      .jury-member.jury-member-big {
        max-width: 470px;
        margin: 0 auto;
        .title { bottom: 4em; }
        .name { bottom: 0em; }
      }
      .jury-member:nth-child(1) { transition-delay: 200ms; }
      .jury-member:nth-child(2) { transition-delay: 400ms; }
      .jury-member:nth-child(3) { transition-delay: 600ms; }
      .jury-member:nth-child(4) { transition-delay: 800ms; }
      .jury-member:nth-child(5) { transition-delay: 1000ms; }
      .jury-member:nth-child(6) { transition-delay: 1200ms; }
      .jury-member:nth-child(7) { transition-delay: 1400ms; }
      .jury-member:nth-child(8) { transition-delay: 1600ms; }
      .jury-member:nth-child(9) { transition-delay: 1800ms; }
      .jury-member:nth-child(10) { transition-delay: 2000ms; }
      .jury-member:nth-child(11) { transition-delay: 2200ms; }
      .jury-member:nth-child(12) { transition-delay: 2400ms; }
      &.in-view .jury-member {opacity:1; }
      .jury-member .picture { width: 100%; position:relative; overflow:hidden; }
      .jury-member .picture img { width: 100%;transform-origin:center center; transform:scale(1); transition:transform 300ms; }
      .jury-member .picture:hover img { transform: scale(1.1);}
      .jury-member .picture .hover {
          display: flex;
          flex-flow: column;
          justify-content: center;
          opacity:0; position: absolute; top: 0; right: 0; bottom: 0; left: 0;
          background: rgba(0,0,0,0.6); color: white;
          font-weight: bold; padding: 1em 1em 2em 1em; text-transform: uppercase;
          line-height: 1.2em;
          font-size: .7em;
          text-align: center; transition: opacity 300ms;
      }
      .jury-member.jury-member-big .picture .hover {
        font-size: 1em;
        align-items: center;
      }
      @media screen and (min-width: 1600px) {
        .jury-member .picture .hover {
          font-size: 1em;
          align-items: center;
        }
      }

      .jury-member .picture:hover .hover > div, .jury-member:hover .picture .hover > span {transition: transform 300ms; transform:translate(0%, 0); }
      .jury-member .picture:hover .hover {  transform:translate(0%, 0); opacity:1; }
      .jury-member .picture .hover > div, .jury-member .picture .hover > span {transition: transform 300ms; transform:translate(100%, 0); }
      .jury-member .name {
          margin-top: 1em; padding: 1em 0 0 1em;
          position: absolute; bottom: -1.6em; left: 20%;right: 0;
          background: #ededed; font-weight: bold; text-transform: uppercase;
          line-height: 1.2em; font-size: 1.1em; }
      .jury-member .title { line-height: 2.4em; padding: 0.1em 0.4em; font-weight: bold; background: #fed700; left: 0em; bottom: 1.4em; position: absolute; font-size: 0.7em; text-transform: uppercase; min-height: 1.7em; min-width: 7em; }
      .jury-member .copyright { font-size: 0.7em; position: absolute; top: 0.4em; right: 0.6em; text-transform: none; font-weight: normal; font-style: italic; color: #999; }
      .jury-member .social { position: absolute; bottom: 3.2em; right: 1em; }
      .jury-member .social a { color: #fff; font-family: $nikon-title; text-transform: uppercase; font-weight: bold; font-size: .8em; line-height: 1em; }
      .jury-member.jury-member-big .social a { font-size: 1em; }
      .jury-member .social a img { height: 1.8em; width: 1.8em; display: inline; margin-left: 0.6em; }
      @media screen and (max-width: 1280px) {
        .jury-members { grid-gap: .2em; grid-row-gap: 3em; }
        .jury-member .name { font-size: .8em; }
        h1 { position: relative; }
      }
      @media screen and (max-width: 1000px) {
        position: relative;padding-left:1rem; padding-right:1rem;
        h1 { display: block; }
        h1::after { right: 0; bottom: 0; left: 4em; top: 0;  }
        .jury-member .picture .hover {
          font-size: 1em;
          align-items: center;
        }
        .jury-member .name {
          padding: 1.2em 0 0 0;
          bottom: 0;
        }
        #jury-carousel {
          max-width: 60%;
          margin: 2em auto;
          .slick-slide { text-align: center; }
          .slick-prev, .slick-next {
            &:before { display: none; }
          }
        }
        .jury-member { width: 100%; /*max-width: 200px;*/ margin-left: auto; margin-right: auto; float: none; }
      }
      @media screen and (max-width: 650px) {
        #jury-carousel {
          max-width: 100%;
        }
      }

      #jury-carousel {
        .slick-dots li {
          &, & button { width: 10px; }
        }
      }
    }



    /** Slider de films dans la home **/
    .slider{

    }
  }
}



/* Block Twitter + Commentaires Facebook */
#comments-twitter {
  background: #eae9e9;
  .container-fluid { display: flex; padding-top: 2rem; padding-bottom: 0; justify-content: space-between; }
  .container-fluid::before,
  .container-fluid::after { display: none; }
  .container-fluid .col { width: 46%; }
  h1 { text-align: left; position: relative; margin: 0 0 2em 0; font-size: 0.9em; text-transform: uppercase; padding-left: 4em; line-height: 1em; }
  h1:before { content: ""; position: absolute; left: 0; top: 0.2em; bottom: 0.3em; width: 3.6em; background: #fed700; }
  .comments #last-comments { height: 500px; overflow-x: auto; font-family: $nikon-text; }
  .comment-block { height: 120px; }
  .comment-block a.pic { display: block;  position : relative; }
  .comment-block a.pic img.pic { float: left; width: 160px; margin-right: 10px; }
  .comment-block a.pic img.play { position: absolute; left: 60px; top: 25px; width: 40px; }
  .comment-block h6 { line-height: 1.1; margin: 0; font-size: 1em;
                      overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
  .comment-block .title { display: block; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
  .comment-block .comment { margin: 0;font-size: 0.9em; line-height: 1.1; color: #474747; }
  .comment-block .link { text-decoration: underline; font-size: 0.8em; color: #474747; }
  @media screen and (max-width: 650px) {
     display: none;
  }
}
