/* Page d'inscription / de profil */
.btn-google {
  height: 40px;
  background-color: #dd4b39;
  color: #fff !important;
  font-family: $nikon-text;
  position: relative;
  font-size: 0.6rem;
  border-radius: 0;
  border: none;
  text-decoration: none !important;
  min-width: 260px;

  &:active {
    background-color: #dd4b39;
    border: none;
  }
  &:hover {
    color: #fff;
  }
}

// Disable Google connect on webviews
body[data-iswebview="true"] {
  #profile.register_vote .btn-google,
  .orseparator-container {
    display: none;
  }
  .page.login .btn-google,
  .orseparator-container {
    display: none;
  }
}

.custom-switch.switch-nikon {
  padding-left: 1.8rem;

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: $nikon-yellow;
    background-color: $nikon-yellow;
  }
  .custom-control-label::before {
    border: #000 solid 1px;
    outline: none;
    box-shadow: none !important;
    -webkit-appearance: none;
  }
  .custom-control-label::after {
    background-color: #000;
    outline: none;
    box-shadow: none !important;
    -webkit-appearance: none;
  }
}

.glyphicon-xl {
  font-size: 2rem;
}
.glyphicon-lg {
  font-size: 1.5rem;
}
.glyphicon-md {
  font-size: 1rem;
}

#profile {
  margin: 0 auto;
  border: none;
  position: relative;
  padding-left: 6px;
  padding-right: 6px;
  max-width: 938px;
  padding-top: 7rem;
  font-family: $nikon-text;

  h1 {
    color: #000;
    font-size: 1.5em;
  }
  h1.header {
    background-color: #ffd500;
    padding: 10px 25px;
    margin: 0;
    color: #000;
  }
  h2 {
    font-family: $nikon-title;
    font-weight: bold;
    font-size: 1.1rem;
    text-transform: uppercase;
  }
  h3 {
    font-family: $nikon-title;
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: bold;
  }
  h4 {
    font-family: $nikon-title;
    font-size: 0.7rem;
    text-transform: uppercase;
    font-weight: bold;
  }

  legend {
    font-size: 1.3em;
    font-family: $nikon-subtitle;
    text-transform: uppercase;
  }

  .follow-us h2 {
    font-size: 0.9em;
  }
  .subtitle {
    color: black;
    margin: 6px;
    font-size: 1em;
  }
  .steps-bar {
    width: 100%;
    height: 15px;
    clear: both;
  }
  .steps-bar .step {
    height: 15px;
    width: 25%;
    position: relative;
    overflow: hidden;
    float: left;
  }
  .steps-text .step {
    width: 25%;
    position: relative;
    overflow: hidden;
    float: left;
  }
  .steps-text .step h3 {
    padding: 8px 6px;
    margin: 0;
    color: #000;
    font-size: 0.6rem;
    text-transform: uppercase;
    font-family: $nikon-title;
    font-weight: bold;
  }
  .steps-text .step h3 .desc {
    font-weight: normal;
  }
  .steps-text .step h3.inactive {
    color: $nikon-gray-3;
    text-decoration: none;
  }
  .steps-bar .step [class*="corner"] {
    position: absolute;
    width: 50px;
    height: 40px;
  }
  .steps-bar .step .corner-top {
    right: -20px;
    top: -20px;
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -o-transform: rotate(60deg);
  }
  .steps-bar .step .corner-bottom {
    right: -20px;
    bottom: -20px;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
  }
  .steps-bar .step .title-spot {
    height: 40px;
  }
  .steps-bar .step1 .title-spot {
    background-color: #000;
  }
  .steps-bar .step1 [class*="corner"],
  .steps-bar .step2 .title-spot {
    background-color: #fff;
  }
  .steps-bar .step2 [class*="corner"],
  .steps-bar .step3 .title-spot {
    background-color: #fff;
  }
  .steps-bar .step3 [class*="corner"],
  .steps-bar .step4 .title-spot {
    background-color: #fff;
  }

  .yellow-button {
    color: black;
    background: #ffe256;
    background: -moz-linear-gradient(top, #ffe256 0%, #ffd500 100%);
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #ffe256),
      color-stop(100%, #ffd500)
    );
    background: -webkit-linear-gradient(top, #ffe256 0%, #ffd500 100%);
    background: -o-linear-gradient(top, #ffe256 0%, #ffd500 100%);
    background: -ms-linear-gradient(top, #ffe256 0%, #ffd500 100%);
    background: linear-gradient(to bottom, #ffe256 0%, #ffd500 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffe256', endColorstr='#ffd500',GradientType=0 );
  }
  .yellow-button:hover {
    background: #ffd500;
    background: -moz-linear-gradient(top, #ffd500 0%, #ffe256 100%);
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #ffd500),
      color-stop(100%, #ffe256)
    );
    background: -webkit-linear-gradient(top, #ffd500 0%, #ffe256 100%);
    background: -o-linear-gradient(top, #ffd500 0%, #ffe256 100%);
    background: -ms-linear-gradient(top, #ffd500 0%, #ffe256 100%);
    background: linear-gradient(to bottom, #ffd500 0%, #ffe256 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffd500', endColorstr='#ffe256',GradientType=0 );
  }
  .block {
    padding-bottom: 20px;
  }
  .block a {
    text-decoration: underline;
  }
  .block h2 {
    font-family: $nikon-title;
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }
  .block h4 {
    font-family: $nikon-subtitle;
    font-size: 0.7rem;
    font-weight: bold;
    text-transform: uppercase;
    margin: 1rem 0 0;
  }
  .block .info {
    font-size: 1em;
    color: $nikon-gray-3;
  }
  .block .info b {
    font-size: 1em;
    display: inline-block;
    margin-bottom: 4px;
  }
  .text-error {
    text-align: center;
    color: red;
    font-weight: bold;
  }
  .button-block {
    text-align: right;
    margin-bottom: 50px;
    margin-top: 20px;
  }
  .button-block button,
  .button-block a {
    margin: 0 10px;
    text-transform: uppercase;
    display: inline-block !important;
    font-family: "Nexa";
    padding: 15px;
  }
  .button-block .cancel {
    background-color: transparent;
    border: none;
  }
  .button-block .cancel:hover,
  .button-block .cancel:active {
    background-color: #464646;
    color: #fff;
  }
  label {
    display: inline-block;
    color: $nikon-gray-3;
    margin: 6px 0px 10px 10px;
    cursor: auto;
  }
  label.ko {
    color: #b94a48 !important;
  }
  .span {
    margin-left: 0;
    border: none;
  }
  .span label {
    width: 35%;
    text-align: right;
    font-size: 1em;
  }
  label.mandatory:after {
    content: "\00a0*";
    color: red;
  }
  .span.full label {
    width: 17.5%;
  }
  .span.half {
    width: 49.8%;
  }
  .span.half.right {
    float: right;
  }
  .span.full {
    width: 100%;
    border-left: 0px;
  }
  .span .text-error {
    margin: 1px 30px;
    padding: 0;
  }
  .span input {
    float: right;
  }
  .span.half input[type="text"],
  .span.half input[type="textarea"],
  .span.half input[type="password"] {
    width: 56%;
  }
  .span.half input[type="number"],
  .span.half input[type="number"]:focus {
    width: 26.5%;
    position: relative;
    background: #fff;
    height: 30px;
    margin: 0;
    text-align: center;
    font-size: 1.3em;
    color: #747474;
    border: none;
  }
  .span.full input[type="text"],
  .span.full textarea,
  .span.full input[type="password"] {
    width: 78%;
    padding: 0px 1.5%;
    resize: none;
  }
  input.form-control {
    font-size: 1em;
    color: $nikon-black;
  }
  input.field,
  input.field:focus,
  select.field,
  select.field:focus,
  textarea.field {
    box-shadow: none;
    -webkit-box-shadow: none;
    background: #fff;
    color: black;
    padding: 0px 3%;
    margin: 4px 10px;
    outline: none;
    height: 30px;
    border-radius: 0;
    border: none;
  }
  input.field.disabled {
    background: #ebebeb;
    color: rgb(116, 116, 116);
  }
  .span select,
  .span select:focus {
    float: right;
    width: 56%;
    padding: 0 2%;
  }
  .span #birthday {
    float: right;
    width: 56%;
    margin: 4px 10px;
    display: inline-block;
  }
  input[type="number"].field {
    float: left;
  }
  input[type="number"]#day,
  input[type="number"]#day2 {
    border-radius: 0;
    font-size: 1em;
  }
  input[type="number"]#month,
  input[type="number"]#month2 {
    border-radius: 0;
    font-size: 1em;
  }
  input[type="number"]#year,
  input[type="number"]#year2 {
    border-radius: 0;
    font-size: 1em;
  }
  input.field[type="number"]:focus {
    border-color: #ccc;
  }
  button,
  label.button-label {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  label.button-label {
    cursor: auto;
  }
  form .btn-inverse,
  form .btn-inverse:hover {
    display: block;
    color: #000;
    background-color: white;
    background-image: none;
    padding: 8px 15px;
  }
  form .btn-inverse:hover {
    background-color: #c2a200;
  }
  form .btn-inverse.disabled:hover {
    background-color: #ffd500;
  }
  form .btn-inverse:active {
    background-color: #bb9c01;
  }
  label#submit-label,
  label.button-label {
    color: #ffd500;
    font-size: 1em;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  p b {
    color: #1d1d1d;
  }
  textarea,
  textarea:focus {
    width: 100%;
    resize: vertical;
  }

  .public-info-container {
    .form-group {
      width: 100%;
      input {
        width: 100%;
      }
    }
  }

  &.user {
    max-width: initial;
    margin-top: 3.8rem;
    @media screen and (max-width: 1000px) {
      margin-top: 2.5rem;
    }
    padding: 0;
    // min-height: 60vh;
    .block-title {
      font-family: $nikon-title;
      font-size: 0.65rem;
      font-weight: 700;
    }
    .btn {
      padding: 0.375em 0.75em;
    }
    .btn-nikon-yellow {
      font-size: 0.7rem;
    }
    .avatar img {
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      width: 100px;
      max-height: 100px;
    }
    a {
      text-decoration: none;
    }
    a.underline {
      text-decoration: underline;
    }
    a.edit-profile {
      font-size: 0.6rem;
    }
    a.delete-profile {
      font-size: 0.6rem;
    }
    a.codirector-edit {
      float: right;
      margin-top: 0px;
    }
    .personal-info {
      font-size: 0.8rem;
      address {
        font-size: 0.75rem;
      }
      strong {
        text-transform: uppercase;
        font-family: $nikon-title;
      }
    }
    .video-info .text-warning {
      margin-bottom: 0.2rem;
    }
    .video-info a {
      text-decoration: none;
      margin-top: 5px;
    }
    .video-info .title {
      text-transform: uppercase;
    }
    .video-info {
      .row {
        background: none;
        border: none;
      }
      .edit-actions {
        text-align: right;
      }
      .edit-actions p {
        text-align: center;
        margin-top: 1rem;
      }
      .edit-actions .error {
        display: inline-flex;
      }
      .embed-responsive {
        min-height: 227px;
      }
      .embed-responsive-item {
        min-height: 227px;
      }
      a.video-edit {
        float: right;
        margin-right: 10px;
        margin-top: 0;
      }
      a.video-first-edit {
        margin-top: 8px;
      }
      .edit-actions #change-thumbnail {
        padding-top: 2rem;
      }
    }
    .video-info form {
      margin-top: 15px;
      text-align: center;
    }
    a.video-upload {
      text-transform: uppercase;
      color: #fff;
      background: red;
      border: none;
      font-family: $nikon-title;
      border-radius: 0;
      font-weight: bold;
    }
    a.video-upload:hover {
      color: #fff;
      background: indianred;
    }
    .submit-thumbnail {
      &[disabled] {
        cursor: not-allowed;
      }
    }
    .add-codirector-wrapper { padding: 1em 0; text-align: center; }
    .episode-wheel {
      margin-left: 2rem;
      width: 5rem;
      height: auto;
      border-radius: 50%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 5px solid transparent;
    
      &.step-1 {
        border-top-color: $nikon-yellow;
        border-right-color: $nikon-gray-2;
        border-bottom-color: $nikon-gray-2;
        border-left-color: $nikon-gray-2;
      }

      &.step-2 {
        border-top-color: $nikon-yellow;
        border-right-color: $nikon-yellow;
        border-bottom-color: $nikon-gray-2;
        border-left-color: $nikon-gray-2;
      }

      &.step-3 {
          border-top-color: $nikon-yellow;
          border-right-color: $nikon-yellow;
          border-bottom-color: $nikon-gray-2;
          border-left-color: $nikon-gray-2;
      }

      &.step-4 {
        border-top-color: $nikon-yellow;
        border-right-color: $nikon-yellow;
        border-bottom-color: $nikon-yellow;
        border-left-color: $nikon-gray-2;
      }

      &.step-5 {
        border-top-color: $nikon-yellow;
        border-right-color: $nikon-yellow;
        border-bottom-color: $nikon-yellow;
        border-left-color: $nikon-gray-2;
      }
    
      &.step-6,
      &.complete {
        border-top-color: $nikon-yellow;
        border-right-color: $nikon-yellow;
        border-bottom-color: $nikon-yellow;
        border-left-color: $nikon-yellow;
      }
    }

    .episode-number {
      position: absolute;

      font-size: 1.2em;
      font-family: $nikon-title;
      font-weight: 700;
      color: black;
    }
  }
  &.upload,
  &.confirm {
    .upload-form {
      text-align: center;
      border-style: dashed;
      border-color: #cdcdcd;
      margin: 20px 50px;
      background-color: #f9f9f9;
    }
    .upload-form .btn,
    .upload-form .btn.fade.in {
      position: relative;
      margin: 0 auto;
      cursor: pointer;
      text-align: center;
      color: black;
      font-size: 0.8rem;
      text-decoration: none;
    }
    .upload-form div.btn {
      width: 60px;
    }
    .upload-form .btn.fade {
      cursor: default;
    }
    .upload-form input[type="file"] {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      opacity: 0;
      width: 100%;
      height: 150px;
      z-index: 10000;
    }
    .upload-form input[type="text"] {
      display: block;
      margin: 10px auto;
    }
    .upload-form .yellow-button {
      text-decoration: none;
      width: auto;
      height: auto;
      padding: 2px 10px;
      display: inline-block;
      border-color: $nikon-yellow;
      margin-bottom: 1em;
    }
    .upload-form img {
      margin: 10px auto;
      display: block;
    }
    .upload-form .processing .info {
      margin: 20px 0;
    }
    .upload-form .processing .bar {
      background-color: $nikon-yellow;
      background-image: none;
    }
    .upload-form .processing .hidepart {
      width: 100%;
      position: absolute;
      border-style: dotted;
      left: 0px;
      border-width: 15px 0px;
      border-color: #f9f9f9;
    }
    .upload-form .processing .progress {
      width: 40%;
      height: 30px;
      margin: 0 auto;
      position: relative;
      background-color: #b4b4b4;
      background-image: none;
    }
    .specs {
      display: inline-block;
      padding: 20px 0;
      width: 25%;
      vertical-align: top;
      text-align: center;
    }
    .warning {
      text-align: center;
      background-color: #dc3545;
      color: #fff;
      margin: 5px 50px;
      padding: 5px 10px;
      margin-top: 20px;
    }
    .video-responsive {
      width: 100%;
      padding-top: 56.25%;
      height: 0px;
      position: relative;
      video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &.edit-video {
    .title {
      margin: 0 auto;
      font-size: 2rem;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 42px;
    }
    .title .iam {
      text-align: center;
      width: 135px;
      border-style: none;
      background-color: $nikon-yellow;
      font-weight: bold;
      color: #000;
      padding: 0 15px;
      float: left;
    }
    .title input {
      width: 450px;
      height: 38px;
      border-radius: 0;
      border-style: solid;
      border-color: $nikon-yellow;
      border-width: 2px;
      background-color: #fff;
      color: #000;
      line-height: inherit;
      font-size: 0.7rem;
      margin: 0 auto;
      padding: 0 20px;
    }
    /* .title input { width: 100%; height: 38px; border-radius: 0; border-style: solid; border-color: #ffd500; border-width: 2px; background-color: #FFF; color: #000; font-family: "Nexa"; line-height: inherit; font-size: inherit; text-transform: inherit; margin: 0 auto; padding: 0 20px; float: left; }*/
    .input-block,
    .switch-block {
      position: relative;
      margin: 0 50px;
    }
    .input-block b {
      display: block;
      font-size: 0.7rem;
    }
    .input-block textarea {
      resize: none;
      background-color: #fff;
      border-color: #fff;
      border-radius: 0;
      padding: 0.4em;
    }
    .input-block .counter {
      text-align: right;
      font-size: 0.7em;
    }

    .input-block.competition  #language-options label,
    .input-block.tags label {
      padding: 2px 6px 0px 25px;
      font-size: 0.6rem;
      border: 1px solid $nikon-yellow;
      color: #000;
      text-transform: uppercase;
      display: inline-block;
      cursor: pointer;
      position: relative;
      margin-right: 10px;
      margin-left: 0;
    }
    .input-block.competition  #language-options label:before,
    .input-block.tags label:before {
      content: "o";
      display: inline-block;
      width: 22px;
      margin-right: 10px;
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      border-right: 1px solid $nikon-yellow;
      color: #fff;
      background: #fff;
    }
    .input-block.competition  #language-options input[type="checkbox"],
    .input-block.tags input[type="checkbox"] {
      display: none;
    }
    .input-block.competition  #language-options input[type="checkbox"]:checked + label:before,
    .input-block.tags input[type="checkbox"]:checked + label:before {
      content: "✖";
      font-size: 1rem;
      color: #000;
      text-align: center;
      line-height: 25px;
      background-color: $nikon-yellow;
      height: 25px;
      margin-bottom: -2px;
    }

    .input-block.row {
      margin-top: 1rem;
      border: 0 none;
      background-color: transparent;
      .info {
        text-align: left;
      }
      .no-pad-h {
        padding-right: 0;
        padding-left: 0;
      }
    }
    .normalize-font-size {
      font-size: 0.65rem;
    }
  }

  &.subtitles {
    .subtitle-section {
      margin: 2em 0em;
      padding: 1em;
      &.eligible_ok {
        border: 2px solid green;
      }
      &.subtitle-fr {
      }
      &.subtitle-inter {
      }

      .btn-delete-subtitle {
        color: white;
        text-decoration: none;
        font-size: 0.9em;
        margin: 1em 0;
      }
    }
  }

  &.register {
    .cropArea {
      label {
        display: block;
        margin: 2em auto;
      }
      canvas {
        margin: 2em auto;
        display: block;
      }
    }
  }

  &.register_vote {
    .button-block {
      text-align: center;
      margin-top: 0;
    }
  }

  .card {
    border-radius: 0;
    border: none;
    .card-header {
      border-radius: 0;
      background-color: rgba(0, 0, 0, 0.12);
      text-transform: uppercase;
      font-size: 0.65rem;
      line-height: 0.65rem;
      padding: 0.8rem 0.8rem 0.6rem;
    }
    span.btn-square {
      margin: 4px;
    }
  }

  .btn-square {
    height: 110px;
    width: 110px;
    font-size: 0.5rem;
    text-transform: uppercase;
    font-family: $nikon-title;
    font-weight: 700;
    border-radius: 0;

    a {
      color: #000;
    }

    &.btn-white {
      background-color: #fff;
    }
    &.btn-green {
      background-color: #2cb85a;
      color: white;
    }
    &.btn-gray {
      background-color: $nikon-gray-1;
    }
    &.btn-dark-gray {
      background-color: $nikon-gray-2;
    }
    &.btn-yellow {
      background-color: $nikon-yellow;
    }
    &.btn-orange {
      background-color: #ffc107;
    }
    &.btn-orange-soft {
      background-color: #ffd760;
    }
    &.btn-outline {
      border: 2px solid #000;
    }
    &.btn-red {
      background-color: red;
      color: white;
      a {
        color: white;
      }
    }
    &.btn-blue {
      background-color: #5886D3;
      color: white;
    }
  }
}

.container-see-also {
  background-color: $nikon-black;
  .btn-nikon-yellow {
    color: $nikon-black;
    padding: 5px 16px;
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 920px) {
  #profile .steps-bar .desc {
    display: none;
  }
  #profile .span.half input[type="text"],
  #profile .span.half input[type="textarea"],
  #profile .span.half input[type="password"] {
    width: 40%;
  }
  #profile .span #birthday {
    width: 42.5%;
  }
  #profile .span.half input[type="number"],
  #profile .span.half input[type="number"]:focus {
    width: 24%;
  }
  #profile .span.full input[type="text"],
  #profile .span.full input[type="textarea"],
  #profile .span.full input[type="password"] {
    width: 68%;
  }
  #profile .span.full label {
    width: 23.5%;
  }
  #profile .span select,
  #profile .span select:focus {
    width: 42.5%;
  }
  #profile.register .span label {
    width: 47%;
    margin: 10px 0px 10px 10px;
  }
}

@media screen and (max-width: 700px) {
  #profile {
    padding-top: 3.5rem;
  }
  #profile .steps-bar .step h3 {
    font-size: 0.9em;
  }
  #profile.user a.edit-profile {
    float: none;
  }
  #profile.user a.delete-profile {
    float: none;
  }
  #profile.user .video-info a.video-edit {
    float: none;
  }
  #profile.user .video-info img {
    max-width: 100%;
  }
  #profile .title-bar h3 {
    font-size: 20px;
  }
  #profile .title-bar .step2 h3,
  #profile .title-bar .step3 h3 {
    padding-left: 0;
  }
  #profile .span.half {
    width: 100%;
    border-left: 0px;
    border-right: 0px;
  }
  #profile .span.half input[type="text"],
  #profile .span.half input[type="textarea"],
  #profile .span.half input[type="password"],
  #profile .span.full input[type="text"],
  #profile .span.full input[type="textarea"],
  #profile .span.full input[type="password"] {
    width: 40%;
    padding: 0px 1.5%;
  }
  #profile .span input,
  #profile .span #birthday {
    float: none;
  }
  #profile .span select,
  #profile .span select:focus {
    float: none;
    width: 35.5%;
    padding: 0 1%;
  }
  #profile .span.half input[type="text"],
  #profile .span.half input[type="textarea"],
  #profile .span.half input[type="password"],
  #profile .span.full input[type="text"],
  #profile .span.full input[type="textarea"],
  #profile .span.full input[type="password"] {
    width: 45%;
  }
  #profile .span.half input[type="number"],
  #profile .span.half input[type="number"]:focus {
    width: 24%;
  }
  #profile .span #birthday {
    width: 35.5%;
  }
  #profile .block p.info {
    padding: 10px 0px;
  }
  #profile.register .span label,
  #profile.register .span label,
  #profile.register .span.full label {
    width: 35%;
  }
  #profile.upload .upload-form {
    margin: 5px 5px;
    min-height: 200px;
  }
  #profile.upload .warning {
    margin: 5px 5px;
  }
  #profile.upload .specs {
    padding: 0px 0;
    width: 100%;
  }
  #profile.edit-video .input-block,
  #profile.edit-video .switch-block {
    margin: 0 10px;
  }
  #profile.edit-video
    .input-block.tags
    input[type="checkbox"]:checked
    + label:before {
    font-size: 16px;
  }
}
@media screen and (max-width: 550px) {
  #profile.edit-video .title {
    font-size: 22px;
    width: 320px;
  }
  #profile.edit-video .title .iam {
    width: 85px;
  }
  #profile.edit-video .title input {
    width: 100%;
  }
}
@media screen and (max-width: 335px) {
  #profile.user .block {
    position: relative;
    margin-left: 0px;
    margin-top: 125px;
  }
}

#profile.register {
  h1 {
    font-size: 1.2rem;
  }
  ::placeholder {
    color: $nikon-gray-3;
    font-size: 0.5rem;
  }

  .info.facebook button {
    background-color: #395591;
    color: white;
    font-size: 0.6rem;
    border: none;
    border-radius: 0;
    margin: 0 auto;
    min-width: 260px;
    height: 40px;
  }
  .btn-nikon-yellow.btn-register-email {
    min-width: 260px;
    height: 40px;
    font-size: 0.6rem;
    text-decoration: none;
    font-family: $nikon-text;
    text-transform: none;
    line-height: 1rem;
    span {
      margin-right: 0.2rem;
    }
    &:hover {
      background-color: $nikon-yellow !important;
      color: black !important;
      border-color: $nikon-yellow !important;
    }
  }
  .competition {
    margin: 0 auto 1em;
    max-width: 46em;
    padding: 0.5em;
    label {
      color: #000;
      font-size: 1.3em;
      vertical-align: middle;
      display: inline;
      line-height: 1.8em;
    }
  }

  .schools select.field,
  .schools select.field:focus {
    margin: 10px auto;
    padding: 5px 20px;
    padding-right: 5px;
    display: block;
    width: 280px;
  }

  .input-checkbox {
    margin-left: 7%;
    position: relative;
    label {
      cursor: pointer;
    }
    input[type="checkbox"] {
      position: absolute;
      right: 100%;
      top: 10px;
    }
  }

  .avatar {
    display: block;
    margin: 10px auto;
    max-width: 128px;
  }
  .language-switcher {
    text-align: center;
    margin: 0.6em 0.8em 3em 0.8em;
    font-size: 1em;
    img {
      position: relative;
      top: -1px;
      margin: 0 2px;
    }
  }
  @media screen and (max-width: 650px) {
    .competition {
      max-width: none;
      label {
        font-size: 1em;
        max-width: auto;
      }
    }
  }
}

.orseparator {
  text-align: center;
  margin: 1em auto;
  font-weight: bold;
  font-size: 0.9rem;
  font-family: $nikon-title;
  text-transform: uppercase;
  overflow: hidden;
}
.orseparator:before,
.orseparator:after {
  background-color: #000;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}
.orseparator:before {
  right: 0.5em;
  margin-left: -50%;
}
.orseparator:after {
  left: 0.5em;
  margin-right: -50%;
}
@media screen and (max-width: 700px) {
  .orseparator-container {
    width: 60%;
    margin-left: 20%;
  }
}

/* Pages de login, nouveau password, oubli de password */
.page.login {
  font-family: $nikon-text;
  padding-top: 7rem;
  margin: 0 auto;
  border: none;
  position: relative;
  padding-left: 6px;
  padding-right: 6px;
  max-width: 750px;
  min-height: 80vh;

  &.popup {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
  }
  &.popup .login-header h1 {
    margin-top: 20px !important;
  }

  .message {
    text-align: center;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .message.error {
    color: #ad1a2b;
  }
  .message.ok {
    color: #16aa51;
  }

  .error {
    color: red;
    font-size: 0.8em;
    border: 1px solid red;
    text-align: center;
    padding: 0.5em;
    margin: 1em auto;
  }

  .rm-container {
    display: flex;
    justify-content: left;
    align-items: end;
    text-align: center;
    line-height: 0.5rem;
    padding-bottom: 0.1rem;
  }

  div.facebook-connect-section {
    text-align: center;
    font-size: 16px;
    color: white;
    margin-left: 0;
  }
  .facebook-connect-section button {
    height: 40px;
    background-color: #395591;
    color: #fff;
    font-family: $nikon-text;
    position: relative;
    font-size: 0.6rem;
    border-radius: 0;
    border: none;
    min-width: 260px;
  }
  .facebook-connect-section button:active {
    background-color: #395591;
    border: none;
  }
  form .span.full input {
    width: 50%;
  }
  .login-header {
    width: 100%;
    padding-bottom: 0.6rem;
  }
  .login-header h1 {
    color: #1d1d1d;
    padding: 10px 25px;
    font-size: 1.5rem;
    margin: 0;
  }
  .span5,
  .span4 {
    margin: 0 auto;
  }
  p.info {
    padding: 10px 50px;
    margin: 0 auto;
    font-size: 0.7rem;
  }

  .btn-nikon-yellow {
    padding: 5px 16px;
    font-size: 0.7rem;
  }
  a.password {
    display: block;
    margin-top: 15px;
  }
  a:hover {
    text-decoration: underline;
  }
  .form-control {
    font-size: 0.7rem;
  }
  input.field,
  input.field:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    background: #fff;
    border-color: #cdcdcd;
    color: black;
    padding: 2px 3%;
    outline: none;
    height: 30px;
    font-size: 0.7rem;
  }
  &.popup .orseparator {
    width: 100%;
  }
  .separator {
    float: left;
    background: black;
    width: 1px;
    height: 100px;
  }
  @media screen and (max-width: 700px) {
    padding-top: 3rem;
    .login-header {
      text-align: center;
    }
    .login-header h1 {
      display: inline-block;
      margin: 10px;
    }
    .form-control {
      width: 90%;
      margin-left: 5%;
    }
    .span5,
    .span4 {
      width: 100%;
      margin: 0;
      text-align: center;
    }
    .span5 .form-horizontal label {
      margin: 0 auto 5px auto;
      float: none;
      text-align: center;
    }
    .span5 .form-horizontal .controls {
      clear: both;
      margin-left: 0;
    }
    .btn-nikon-yellow {
      display: inline-block;
      width: auto;
    }
    .rm-container {
      display: block;
    }
  }
}