/* Page de teaser */

.page-teaser{
  padding-left: 0 !important; padding-right: 0 !important;/*margin-top: 5.3rem*/;
  .row{margin-right:0;margin-left:0;}
  #video-bg-container {
    height: 100vh;
    background-color: black;

    #video-bg {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }

    &.hidden {
      display: none;
    }
  }
  .teaser-section{
    background-image: url('/public/img/design/background.png');
    background-size: cover;
    background-position: center center;
    // @media screen and (max-width:650px) {background-image: url('/public/img/design/background_mobile.png');}
  }

  section {
    &.countdown{
      flex-direction: column;
      color: white;
      min-height: 700px;
      justify-content: center;

      @media screen and (max-width: 650px) {
        font-size:.7rem;
        padding:1rem 0;
        min-height: 100vh;
        .timer .legend {
          font-size: 1em;
        }
      }
    }
    &.intro{flex-direction: column;}
    &.choose{flex-direction: column; text-align: center;
      font-size: 2em;margin: 2em auto;
      @media screen and (max-width: 650px) {font-size: 1.5em;}
    }
    &.resume{flex-direction: column; margin-top: 1.5em;font-size:1.2em;
      @media screen and (max-width: 650px) {margin-right:1em;margin-left:1em;}
    }
    &.rdv{flex-direction: column;font-size:1.2em;
      @media screen and (max-width: 650px) {margin-right:1em;margin-left:1em;}
    }

    &.annonces {
      flex-direction: column;
      text-align: center;
      background-image: url('/public/img/design/background.png');
      background-size: cover;
      background-position: top center;
      color: #fff;
      padding: 2.4em 0;
      h1 {
        font-size: 1rem;
        color: #fff;
      }
      p {
        margin: 1.8em 0 2em;
      }
      .button {
        color: #000;
        &:hover {
          color: $nikon-yellow;
        }
      }
    }

    .guillemet {
      color: #373333; font-family: Georgia; font-style: italic; font-size: 7em; font-weight: normal;
      line-height: 0.2em;
      &.left{float:left; margin-right: 0.1em;}
      &.right{float:right;}
    }
  }
}
