/* Pages statiques */

#static-page {
  min-height: 90%;
  background-color: #ffffff;  font-family: $nikon-text;
  font-size: 1.2em; padding:5.5em 2.5em 2em 2.5em;
  h1 { font-size: 2.5em; margin: 40px 0 !important;
    @media screen and (max-width: 650px) { font-size: 2em; margin: 25px 0 !important;}
  }
  #main-block-partners h1 {
    font-size: .9em;
  }
  .title { background: none; color: #000;
           font-family: $nikon-title; font-weight: bold;
           margin-top: 24px; text-transform: uppercase; }
  .title h3 { font-size:1.6em;}
  h2 {
    font-family: $nikon-title;
    font-weight: bold;
    font-size: 1.1rem;
    text-transform: uppercase;
  }
  h3 {
    font-family: $nikon-title;
    font-size: .7rem;
    text-transform: uppercase;
    font-weight: bold;
  }
  h4 {
    font-family: $nikon-title;
    font-size: .7rem;
    text-transform: uppercase;
    font-weight: bold;
  }
  @media screen and (max-width: 650px) { padding-top: 3rem; }
}


/* Page FAQ */
#static-page.faq {
  .accordion-group {  margin-top: 16px; }
  .accordion-heading {
    background-image: linear-gradient(#ffffff 0%, #e2dede 100%); border: 1px solid #ccc;border-radius:3px;
  }
  .accordion-toggle {
    display: inline-block;width: 100%;
    color: #000;  padding: 1em;
    font-size: 1em;
    background: url('/public/img/design/arrow-up.png') no-repeat scroll 98% center transparent; }
  .accordion-toggle.collapsed { background-image: url('/public/img/design/arrow-down.png'); }
  .accordion-inner { padding: 22px; border: 1px solid #ccc; border-top:0;border-radius:3px;}
}

/* Pages A Propos et Contact */
#static-page.contact,#static-page.legal-notice {
  padding-left:15rem; padding-right:15rem;
  @media screen and (max-width: 650px){padding-left: 1rem; padding-right: 1rem;}
}

/* Page A Propos */
#static-page.about {
  .btn { color: #000; }

  .about-steps {
    p {
      font-size: .6rem;
    }
    img {
      max-height: 80px;
    }
    .btn {
      font-size: .5rem;
      margin-top: .8rem;
    }
  }

  .jury-solo {
    font-family: $nikon-title;
    font-weight: bold;
    text-transform: uppercase;

    .name {
      margin-top: 1em;
      padding: 1em 0 .6em 1em;
      position: absolute; bottom: -1.6em; left: 20%;right: 0;
      background: #ededed;
      line-height: 1.2em;
      font-size: .5rem;
      text-align: left;
      width: 75%;
    }

    .year {
      line-height: 1.5em; padding: 0.1em 0.4em; background: $nikon-yellow; left: 0em; bottom: 1.4em; position: absolute; font-size: 0.7em; min-height: 1.5em; min-width: 5em;
      text-align: center;
    }
  }

  .yellow-decoration-1:before, .yellow-decoration-2:before {
    content: "";
    background-color: $nikon-yellow;
    position: absolute;
  }
  .yellow-decoration-1:before {
    height: .4rem;
    width: 2.8rem;
    left: 0;
    top: .5rem;
  }
  .yellow-decoration-2:before {
    height: 1.5rem;
    width: 2.5rem;
    bottom: 1.5rem;
    right: 0;
  }
}

/* Page Comment Participer */
#static-page.howto {
  h3 {
    font-family: $nikon-title;
    font-size: .9rem;
    text-transform: uppercase;
    font-weight: bold;
  }

  .flex-wrap {
    display: flex;
    flex-wrap: wrap;
  }

  .btn { color: #000; }

  .challenge {
    background-image: url('/public/img/design/background-teaser-full.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .faq h2 {
    font-size: 1.1rem;
  }
}

/* Page Projections */
#static-page.screenings {
  .map {
    h2 {
      font-size: .8rem;
    }

    text-transform: uppercase;
    font-family: $nikon-title;
    font-size: .6rem;
    font-weight: bold;
  }
}

/* Page Partenaires */
#static-page.partners {
  padding-left: 0; padding-right: 0;
  .section-wrapper { text-align: center; margin-bottom: 4rem; }
  .intertitle {
    display: inline-block; position: relative; margin: 0 0 .5rem 0; font-size: 0.9em; padding-left: 4rem; padding-right: 4rem; line-height: 1rem; text-align: center; font-family: $nikon-title; font-weight: bold !important; color: #000; text-transform: uppercase; margin-bottom: 20px;

    &:before {content: ""; position: absolute; left: 0; top: 0.2rem; bottom: 0.3rem; width: 3.6rem; background: #fed700; max-height: 12px; }
    &:after{ content: ""; position: absolute; right: 0; top: 0.2rem; bottom: 0.3rem; width: 3.6rem; background: #fed700; max-height: 12px; }

    @media screen and (max-width: 650px) {
      font-size: 0.7em;
    }
  }
  .logo-container {
    display: flex; flex-flow: row wrap; justify-content: center; align-items: center;
    a { display: inline-block; margin: 1rem 2rem; }
  }
  a { color: #fff; text-decoration: underline; }
}

/* Page Privacy et Cookies */
#static-page.privacy,#static-page.cookies {
  padding-left:8rem; padding-right:8rem;
  @media screen and (max-width: 650px){padding-left: 1rem; padding-right: 1rem;}
  font-size: 1em;
  h1 { font-size: 1.5em; margin-top: 80px !important; }
  .title h3 { font-weight: bold; font-size:1.2em;}
}
